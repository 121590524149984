import React from 'react';
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";

function SocialMedia(props) {
    console.log('Social Media', props.parentToChild);
    const shareUrl = 'http://github.com';
    const title = 'GitHub';

    return (
        <>
            <div className='social-media'>
                <div className='icon'>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <div>
                        <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                            {count => count}
                        </FacebookShareCount>
                    </div>
                </div>

               

                <div className="icon">
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button"
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>

                <div className="icon">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="Demo__some-network__share-button"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>
            </div>
        </>
    );
}

export { SocialMedia };