import {
    Royal_3p_Front_Card,
    Royal_3p_Front,

    Royal_4p_Front_Card,
    Royal_4p_Front,

    Sigra_3p_Front_Card,
    Sigra_3p_Front,

    Sigra_4p_Front_Card,
    Sigra_4p_Front,

    Royal_Delux_Front_Card,
    Royal_Delux_Front,
    Royal_Delux_Back,
    Royal_Delux_Left,
    Royal_Delux_Right,

    Royal_Delux_2_Front_Card,
    Royal_Delux_2_Front,

    Super_4p_Front_Card,
    Super_4p_Front,

    cinni_royal_dlx
} from '../../images/cinniMiniPedestalFan';

export const cinniMiniPedestalFanData = [
    {
        id: 1,
        image: {
            card: "Super_3p_Front",
            front: "Super_3p_Front",
            back: "Super_3p_Front",
            left: "Super_3p_Front",
            right: "Super_3p_Front",
        },
        name: 'Super 3P',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '2Kg Heavy Fibre Base with Rubber Pad',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 2,
        image: {
            card: Super_4p_Front_Card,
            front: Super_4p_Front,
            back: Super_4p_Front,
            left: Super_4p_Front,
            right: Super_4p_Front,
        },
        name: 'Super 4P',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '18" Heavy Sheet Base',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 3,
        image: {
            card: Royal_Delux_Front_Card,
            front: Royal_Delux_Front,
            back: Royal_Delux_Back,
            left: Royal_Delux_Left,
            right: Royal_Delux_Right,
        },
        name: 'Royal Dlx',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '15" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 4,
        image: {
            card: cinni_royal_dlx,
            front: cinni_royal_dlx,
            back: cinni_royal_dlx,
            left: cinni_royal_dlx,
            right: cinni_royal_dlx,
        },
        name: 'Royal Dlx',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Silver'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '15" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 5,
        image: {
            card: Royal_3p_Front_Card,
            front: Royal_3p_Front,
            back: Royal_3p_Front,
            left: Royal_3p_Front,
            right: Royal_3p_Front,
        },
        name: 'Royal 3P',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '6 Legs Heavy CI Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 6,
        image: {
            card: Royal_4p_Front_Card,
            front: Royal_4p_Front,
            back: Royal_4p_Front,
            left: Royal_4p_Front,
            right: Royal_4p_Front,
        },
        name: 'Royal 4P',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '6 Legs Heavy CI Base with Rubber Fitting',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 7,
        image: {
            card: Sigra_3p_Front_Card,
            front: Sigra_3p_Front,
            back: Sigra_3p_Front,
            left: Sigra_3p_Front,
            right: Sigra_3p_Front,
        },
        name: 'Sigra 3P',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '15" Heavy Sheet Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 8,
        image: {
            card: Sigra_4p_Front_Card,
            front: Sigra_4p_Front,
            back: Sigra_4p_Front,
            left: Sigra_4p_Front,
            right: Sigra_4p_Front,
        },
        name: 'Sigra 4P',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '15" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
];

