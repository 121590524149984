import { Container } from '@mui/material';
import React from 'react'
import { Footer } from '../footer/Footer';
import { Routing } from '../routing/Routing';

function MainContent() {
    return (
        <>
                <Routing />
                <Footer/>
            
        </>
    );
}

export { MainContent };