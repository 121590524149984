import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Stack,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TextField, Grid, Paper, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmationSnackbar } from "./ConfirmationSnackbar";

function GetDetailsModal(props) {
  const theme = useTheme();
  const stylesheet = useMediaQuery(theme.breakpoints.between("xs", "2500"));
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const form = useRef();

  console.log("props", props);

  const productImg = props.prodImg;
  const productName = props.prodName;
  const productDesc = props.prodDesc;
  const prodCategory = props.prodCategory;

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    date:"",
    description: "",
  };

  const sendEmail = (e) => {
    e.preventDefault();

    //@Note:-
    //at the time of adding shree maya fan email address, move send form values to .env file for security

    emailjs
      .sendForm(
        "service_lfod7v9",
        "template_e9lm519",
        form.current,
        "iWJM9vBQf33tUQMzx"
      )
      .then(
        (result) => {
          console.log("result", result.text);
          setOpenSnackbar(true);
        },
        (error) => {
          console.log("error", error.text);
        }
      );
  };

  const title = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "26px",
      fontWeight: "700",
      color: "#282C4B",
    }),
  };
  const formLabel = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "15px",
      fontWeight: "500",
      color: "#333",
      marginBottom: "1em",
    }),
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={() => props.closeModel(true)}
        onClose={() => props.closeModel(false)}
        PaperProps={{sx:{border:"1px solid #00558C",borderRadius:"20px"}}}
      >
        <IconButton
          aria-label="close"
          onClick={() => props.closeModel(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: "2em 2em" }} className="dialog-content">
          <Paper elevation={0} className="paperStyle">
            <Typography sx={{ ...title }}>Let's Collaborate Now!</Typography>
            <Formik initialValues={initialValues}>
              {(props) => (
                <Form ref={form} onSubmit={sendEmail}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={5}>
                      <Typography sx={{ ...formLabel }}>Full Name</Typography>
                      <Field
                        as={TextField}
                        id="outlined-required"
                        name="name"
                        label="Input Your Name"
                        fullWidth
                        error={props.errors.name && props.touched.name}
                        helperText={<ErrorMessage name="name" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Typography sx={{ ...formLabel }}>Email</Typography>
                      <Field
                        as={TextField}
                        name="email"
                        label="Input Your Email"
                        fullWidth
                        error={props.errors.email && props.touched.email}
                        helperText={<ErrorMessage name="email" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} mt={2}>
                      <Typography sx={{ ...formLabel }}>Phone No.</Typography>
                      <Field
                        as={TextField}
                        name="phoneNumber"
                        label="Input Your Phone No."
                        fullWidth
                        error={
                          props.errors.phoneNumber && props.touched.phoneNumber
                        }
                        helperText={<ErrorMessage name="phoneNumber" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} mt={2}>
                      <Typography sx={{ ...formLabel }}>Date</Typography>
                      <Field
                        as={TextField}
                        name="date"
                        type="date"
                        fullWidth
                        error={props.errors.address && props.touched.address}
                        helperText={<ErrorMessage name="date" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                            fontFamily:"Outfit",
                            fontSize:"10px"
                          },
                        }}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Typography sx={{ ...formLabel }}>Message</Typography>
                      <Field
                        as={TextareaAutosize}
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        error={
                          props.errors.description && props.touched.description
                        }
                        helperText={<ErrorMessage name="description" />}
                        style={{
                          border: "0",
                          backgroundColor: "#FEFBE1",
                          outline: "0",
                          padding: "1em 2em",
                          resize: "none",
                          width: "100%",
                          height: "94px",
                          fontFamily:"Outfit"
                        }}
                        className="text-area"
                        placeholder="Write Message"
                        required
                      />
                    </Grid>
                  </Grid>

                  <input name="fanName" type="hidden" value={productName} />
                  <input
                    name="fanCategory"
                    type="hidden"
                    value={prodCategory}
                  />

                  <Button
                    type="submit"
                    className="btnStyle"
                    variant="contained"
                    sx={{
                      "&:hover": { background: "#FCE302", boxShadow: "none" },
                      marginTop: "2em",
                      backgroundColor: "#FCE302",
                      width: "215px",
                      height: "53px",
                      borderRadius: "20px",
                      fontFamily: "Outfit",
                      boxShadow: "none",
                      textTransform: "lowercase",
                    }}
                  >
                   <Typography sx={{
                      fontFamily:"inherit",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#333",
                   }}><span style={{textTransform:"uppercase"}}>S</span>end <span style={{textTransform:"uppercase"}}>M</span>essage</Typography>
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>
          {openSnackbar && (
            <ConfirmationSnackbar closeSnackbar={setOpenSnackbar} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export { GetDetailsModal };
