import {
    Titan_Card,
    Titan,

    Economy_Card,
    Economy,

    Flora_Card,
    Flora,

    Titan_deco_white_Card,
    Titan_deco_white,

    Supreme_Card,
    Supreme,

    Titan_deco_brown_Card,
    Titan_deco_brown,

    Breeze_Card,
    Breeze,

    CG_24_Card,
    CG_24,

    Economy_36_Card,
    Economy_36,

    Titan_deco_36_Card,
    Titan_deco_36,
} from '../../images/ceilingFan';

export const ceilingFanData = [
    {
        id: 1,
        image: {
            card: Titan_Card,
            front: Titan,
            back: Titan,
            left: Titan,
            right: Titan,
        },
        name: 'Titan',
        description: 'Titan comes with Heavy motor in its category for Higher Durability and GP Blades with Unique Twist Angle to deliver High Air with the Sweep Size of 1200mm and 400 RPM.',
        sweepSize: '1200',
        rpm: '400',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Heavy Motor in its Category for Higher Durability',
            'GP Blades with unique Twist angle to deliver higher air',
            'Superior Powder Coating Paint Finish',
            'Double Ball Bearing for smooth & noiseless Operation',
        ],
    },
    {
        id: 2,
        image: {
            card: Economy_Card,
            front: Economy,
            back: Economy,
            left: Economy,
            right: Economy,
        },
        name: 'ECO',
        description: 'ECO comes with Heavy motor in its category for Higher Durability and Double Ball Bearing for smooth & noiseless operation with the Sweep Size of 1200mm and 380 RPM.',
        sweepSize: '1200',
        rpm: '380',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Heavy Motor in its Category for Higher Durability',
            'GP Blades with unique Twist angle to deliver higher air',
            'Superior Powder Coating Paint Finish',
            'Double Ball Bearing for smooth & noiseless Operation',
        ],
    },
    {
        id: 3,
        image: {
            card: Flora_Card,
            front: Flora,
            back: Flora,
            left: Flora,
            right: Flora,
        },
        name: 'Flora',
        description: 'Flora comes with High Speed Motor & Galvanized Blades for High Air Delivery & Air Thrust and full Copper Motor for Durability with the Sweep Size of 1200mm and 380 RPM.',
        sweepSize: '1200',
        rpm: '380',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for High Air Delivery & Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Stylish C.I. Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 4,
        image: {
            card: Titan_deco_white_Card,
            front: Titan_deco_white,
            back: Titan_deco_white,
            left: Titan_deco_white,
            right: Titan_deco_white,
        },
        name: 'Titan Deco',
        description: 'Titan Deco comes with Galvanized Blades which provide High Air Thrust and Full Copper Motor for Durability & High Speed with the Sweep Size of 1200mm and 400 RPM.',
        sweepSize: '1200',
        rpm: '400',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Designer Trims and Bottom Cover Trim adds to the Decor',
            'Powder Coating provide longer life',
            'Full Copper Motor for Durability & High Speed',
            'Galvanized Blades for High Air Thrust',
        ],
    },
    {
        id: 5,
        image: {
            card: Supreme_Card,
            front: Supreme,
            back: Supreme,
            left: Supreme,
            right: Supreme,
        },
        name: 'Supreme',
        description: 'Supreme comes with High Speed Motor & Aluminium Blades for High Air Delivery & Air Thrust and Full Copper Motor for Durability with the Sweep Size of 1200mm and 360 RPM.',
        sweepSize: '1200',
        rpm: '360',
        power: '50',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for High Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Stylish Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 6,
        image: {
            card: Titan_deco_brown_Card,
            front: Titan_deco_brown,
            back: Titan_deco_brown,
            left: Titan_deco_brown,
            right: Titan_deco_brown,
        },
        name: 'Titan Deco',
        description: 'Titan Deco comes with Elegent Looks and Designer Trims & Wide Aluminium Blades for for Higher Air Delivery and Durability with the Sweep Size of 1200mm and 360 RPM.',
        sweepSize: '1200',
        rpm: '360',
        power: '50',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Elegant looks with Designer Trims',
            'Wide Blades for Higher Air Delivery',
            'Full Copper Motor & Aluminium Blades for Durability',
            'Superior Paint Finish for longer life & Impeccable feel',
        ],
    },
    {
        id: 7,
        image: {
            card: Breeze_Card,
            front: Breeze,
            back: Breeze,
            left: Breeze,
            right: Breeze,
        },
        name: 'Breeze',
        description: 'Breeze comes with High Speed Motor & Aluminium Blades for High Air Delivery & Air Thrust and Full Copper Motor for Durability with the Sweep Size of 600mm and 760 RPM.',
        sweepSize: '600',
        rpm: '760',
        power: '65',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery and Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'C.I. Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 8,
        image: {
            card: CG_24_Card,
            front: CG_24,
            back: CG_24,
            left: CG_24,
            right: CG_24,
        },
        name: 'CG 24',
        description: 'CG 24 comes with High Speed Motor & Aluminium Blades for High Air Delivery & Air Thrust and Full Copper Motor for Durability with the Sweep Size of 600mm and 750 RPM.',
        sweepSize: '600',
        rpm: '750',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Glossy Powder Coating Paint Finish for Longer Life',
            'Full Copper Motor for Durability',
        ],

    },
    {
        id: 9,
        image: {
            card: Economy_36_Card,
            front: Economy_36,
            back: Economy_36,
            left: Economy_36,
            right: Economy_36,
        },
        name: 'Economy 36',
        description: 'Economy 36 comes with High Speed Motor & Galvanized Blades for High Air Delivery & Air Thrust and Copper Motor for Durability with the Sweep Size of 900mm and 460 RPM.',
        sweepSize: '900',
        rpm: '650',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery & Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Powder Coating for Superior Paint Finish',
            'Full Copper Motor for Durability',
        ],

    },
    {
        id: 10,
        image: {
            card: Titan_deco_36_Card,
            front: Titan_deco_36,
            back: Titan_deco_36,
            left: Titan_deco_36,
            right: Titan_deco_36,
        },
        name: 'Titan Deco 36',
        description: 'Titan Deco 36 comes with Galvanized Blades for High Speed Air Thrust & Higher Air Delivery and Copper Motor for Durability with the Sweep Size of 900mm and 460 RPM.',
        sweepSize: '900',
        rpm: '460',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Stylish Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
];