import { ceilingFanData } from './CeilingFanData';
import { pedestalFanData } from './PedestalFanData';
import { miniPedestalFanData } from './MiniPedestalFanData';
import { smallFanData } from './SmallFanData';
import { tableFanData } from './TableFanData';
import { cinniCeilingFanData } from './CinniCeilingFanData';
import { cinniMiniPedestalFanData } from './CinniMiniPedestalFanData';
import { cinniPedestalFanData } from './CinniPedestalFanData';
import { cinniTableFanData } from './CinniTableFan';
import { cinniSmallFanData } from './CinniSmallFanData';

export const categoryMap = {
    CeilingFan: ceilingFanData,
    MiniPedestalFan: miniPedestalFanData,
    PedestalFan: pedestalFanData,
    SmallFan: smallFanData,
    TableFan: tableFanData,
    CinniCelingingFan: cinniCeilingFanData,
    CinniMiniPedestalFan: cinniMiniPedestalFanData,
    CinniPedestalFan: cinniPedestalFanData,
    CinniTableFan: cinniTableFanData,
    CinniSmallFan: cinniSmallFanData
}