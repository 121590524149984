import {
    Royal_3b_Front_Card,
    Royal_3b_Front,
    Royal_3b_Back,
    Royal_3b_Left,
    Royal_3b_Right,

    Royal_4b_Front_Card,
    Royal_4b_Front,
    Royal_4b_Back,
    Royal_4b_Left,
    Royal_4b_Right,

    Sigra_3b_Front_Card,
    Sigra_3b,

    Sigra_4b_Front_Card,
    Sigra_4b,
} from '../../images/miniPedestalFan';

export const miniPedestalFanData = [
    {
        id: 1,
        image: {
            card: Royal_3b_Front_Card,
            front: Royal_3b_Front,
            back: Royal_3b_Back,
            left: Royal_3b_Left,
            right: Royal_3b_Right,
        },
        name: 'Royal 3B',
        description: 'Royal 3P comes with Aerodynamic AL 3 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 15mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '6 Legs Heavy CI Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 2,
        image: {
            card: Royal_4b_Front_Card,
            front: Royal_4b_Front,
            back: Royal_4b_Back,
            left: Royal_4b_Left,
            right: Royal_4b_Right,
        },
        name: 'Royal 4B',
        description: 'Royal 4P comes with Aerodynamic AL 3 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 19mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '6 Legs Heavy CI Base with Rubber Fitting',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 3,
        image: {
            card: Sigra_3b_Front_Card,
            front: Sigra_3b,
            back: Sigra_3b,
            left: Sigra_3b,
            right: Sigra_3b,
        },
        name: 'Sigra 3B',
        description: 'Sigra 3B comes with Aerodynamic AL 3 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 15mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '15" Heavy Sheet Base with Rubber Fitting',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 4,
        image: {
            card: Sigra_4b_Front_Card,
            front: Sigra_4b,
            back: Sigra_4b,
            left: Sigra_4b,
            right: Sigra_4b,
        },
        name: 'Sigra 4B',
        description: 'Sigra 4B comes with Aerodynamic AL 3 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 19mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            '15" Heavy Sheet Base with Rubber Fitting',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
];