import {
    Sri_3p_Front_Card,
    Sri_3p_Front,
    Sri_3p_Back,
    Sri_3p_Left,
    Sri_3p_Right,

    Sri_4p_Front_Card,
    Sri_4p_Front,
    Sri_4p_Back,
    Sri_4p_Left,
    Sri_4p_Right,

    Table_12_Front_Card,
    Table_12_Front,
    shatabdi
} from '../../images/cinniTableFan';

export const cinniTableFanData = [
    {
        id: 1,
        image: {
            card: Sri_3p_Front_Card,
            front: Sri_3p_Front,
            back: Sri_3p_Back,
            left: Sri_3p_Left,
            right: Sri_3p_Right,
        },
        name: 'Sri 3P',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 2,
        image: {
            card: Sri_4p_Front_Card,
            front: Sri_4p_Front,
            back: Sri_4p_Back,
            left: Sri_4p_Left,
            right: Sri_4p_Right,
        },
        name: 'Sri 4P',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 3,
        image: {
            card:shatabdi,
            front: shatabdi,
            back: shatabdi,
            left: shatabdi,
            right: shatabdi,
        },
        name: 'Shatabdi',
        sweepSize: '400',
        rpm: '2100',
        power: '95',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation and AL Base',
        ],
    },
    {
        id: 4,
        image: {
            card: Table_12_Front_Card,
            front: Table_12_Front,
            back: Table_12_Front,
            left: Table_12_Front,
            right: Table_12_Front,
        },
        name: 'Table 12"',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
];