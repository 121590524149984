import React from "react";
import { hero_img } from "../../../images/background";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Hero() {
  const theme = useTheme();
  const navigate = useNavigate();
  const responsive = useMediaQuery(theme.breakpoints.between('300','375'));
  const responsive1 =  useMediaQuery(theme.breakpoints.between('375','562'));

  const handleNavigate = () => {
    navigate("/product");
  };

  const btn = {
    ...(responsive && {
        margin:'0.5em 0',
        width:'150px',
        height:'40px',
        fontSize:"14px"
    })
}
const btn1 = {
    ...(responsive1 && {
        margin:'1em 0',
        width:'200px',
        height:'52px',
    })
}

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${hero_img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        className="hero-div-container"
      >
        <Container maxWidth="xl">
          <Box className="heroContainer">
            <h1 className="hero-title">
              Experience ultimate comfort and style with our exquisite
              collection of
            </h1>
            <Box className="hero-content-container">
              <p className="hero-content">
                Ceiling fans, Pedestal fans, Table fans, and Small fans
              </p>
            </Box>
            <p className="hero-descp">
              We are dedicated to providing top-quality cooling solutions that
              enhance your living or working spaces while adding a touch of
              elegance.
            </p>
            <Button
              sx={{
                "&:hover": { background: "transparent", border: "1px solid #FCE302",color:"#FCE302" },
                border: "none",
                background: "#FCE302",
                fontFamily:"Outfit",
                fontSize:"18px",
                width:'300px',
                height:"53px",
                borderRadius:"20px",
                color:"#333",
                textTransform:"capitalize",
                margin:"3em 0 0 0",
                ...btn,
                ...btn1
              }}
              onClick={() => handleNavigate()}
            >
              View Products
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
}

export { Hero };
