import React from 'react';
import { useNavigate } from "react-router-dom";
import { Cinni } from '../../images/companyLogo';
import { Card, CardContent, CardHeader, CardMedia, Typography, Button, Container } from '@mui/material';
import { cinniCeilingFanData, cinniTableFanData, cinniSmallFanData, cinniPedestalFanData, cinniMiniPedestalFanData } from '../../_helpers/data';
import { verticalBg } from '../../images/background';

function CinniFans() {
    const navigate = useNavigate();
    const navigateToCeilingFan = () => navigate('/cinni-ceiling-fan'); //navigate to cinni-ceilingFan page
    const navigateToTableFan = () => navigate('/cinni-table-fan'); //navigate to cinni-tableFan page
    const navigateToSmallFan = () => navigate('/cinni-small-fan'); //navigate to cinni-smallFan page
    const navigateToPedestalFan = () => navigate('/cinni-pedestal-fan'); //navigate to cinni-pedestalFan page
    const navigateToMiniPedestalFan = () => navigate('/cinni-mini-pedestal-fan'); //navigate to cinni-miniPedestalFan page

    const handleClick = (status, method, fanName) => {
        console.log('handleClick', status, method);
        navigate(`/productDetails/${method}/${fanName}`, { state: { key_id: status, type: method } });
    }

    return (
        <>
            <div style={{background:`url(${verticalBg})`,backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
                <div className='cinni-products'>
                   <Container maxWidth='xl'>
                   <div className=''>
                        <div className='fan-heading'>
                            <p>Ceiling <span>Fan</span></p>
                        </div>
                        <div className=''>
                            <div className='fan-card-container fx-wrp'>
                                {cinniCeilingFanData.slice(3, 6).map((it, index) => {
                                    return (
                                        <>
                                            <div className='m-5' key={index}>
                                                <Card sx={{ minWidth: 370,borderRadius:"30px" }} onClick={() => handleClick(it.id, 'CinniCelingingFan', it.name)}>
                                                    <CardHeader sx={{color:"#00558C"}}
                                                        title={`Cinni ${it.name}`}
                                                    />
                                                    
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={it.image.front}
                                                        alt={it.name}
                                                        className='img-mx-length'
                                                    />
                                                   
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <ul className='cinni-desc-list'>
                                                                <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                                <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                                <li>VARIENT: {it.color.map((colName, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index}>{colName}, </span>
                                                                        </>
                                                                    );
                                                                })}
                                                                </li>
                                                            </ul>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='see-more-btn'>
                                <div className='fan-card'>
                                    <Button variant="outlined" onClick={navigateToCeilingFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                                       Explore
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                   </Container>
                   
                    
                    { /* ---------------------------------- Table Fan ---------------------------------------- */}
                   <Container maxWidth='xl'>
                   <div className=''>
                        <div className='fan-heading'>
                            <p>Table <span>Fan</span></p>
                        </div>
                        <div className=''>
                            <div className='fan-card-container fx-wrp'>
                                {cinniTableFanData.slice(1, 4).map((it, index) => {
                                    return (
                                        <>
                                            <div className='m-5' key={index}>
                                                <Card sx={{ minWidth: 370,borderRadius:"30px" }} className='fn-crd' onClick={() => handleClick(it.id, 'CinniTableFan', it.name)}>
                                                    <CardHeader sx={{color:"#00558C"}}
                                                        title={`Cinni ${it.name}`}
                                                    />
                                                   
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={it.image.card}
                                                        alt={it.name}
                                                        className='img-mx-length'
                                                    />
                                                   
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <ul className='cinni-desc-list'>
                                                                <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                                <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                                <li>VARIENT: {it.color.map((colName, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index}>{colName}</span>
                                                                        </>
                                                                    );
                                                                })}
                                                                </li>
                                                            </ul>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='see-more-btn'>
                                <div className='fan-card'>
                                    <Button variant="outlined" onClick={navigateToTableFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}} >
                                        <span className='explore-btn'>Explore</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                   </Container>
                  
                    { /* ---------------------------------- Small Fan ---------------------------------------- */}
                    <Container maxWidth='xl'>
                    <div className=''>
                        <div className='fan-heading'>
                            <p>Small <span>Fan</span></p>
                        </div>
                        <div className=''>
                            <div className='fan-card-container fx-wrp'>
                                {cinniSmallFanData.slice(1, 4).map((it, index) => {
                                    return (
                                        <>
                                            <div className='m-5' key={index}>
                                                <Card sx={{ minWidth: 370,borderRadius:'30px' }} className='fn-crd' onClick={() => handleClick(it.id, 'CinniSmallFan', it.name)}>
                                                    <CardHeader sx={{color:"#00558C"}}
                                                        title={`Cinni ${it.name}`}
                                                    />
                                                  
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={it.image.card}
                                                        alt={it.name}
                                                        className='img-mx-length'
                                                    />
                                                    
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <ul className='cinni-desc-list'>
                                                                <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                                <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                                <li>VARIENT: {it.color.map((colName, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index}>{colName}</span>
                                                                        </>
                                                                    );
                                                                })}
                                                                </li>
                                                            </ul>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='see-more-btn'>
                                <div className='fan-card'>
                                    <Button variant="outlined" onClick={navigateToSmallFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}} >
                                        <span className='explore-btn'>Explore</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Container>
                    
                    { /* ---------------------------------- Pedestal Fan ---------------------------------------- */}
                    <Container maxWidth='xl'>
                    <div className=''>
                        <div className='fan-heading'>
                            <p>Pedestal <span>Fan</span></p>
                        </div>
                        <div className=''>
                            <div className='fan-card-container fx-wrp'>
                                {cinniPedestalFanData.slice(3, 6).map((it, index) => {
                                    return (
                                        <>
                                            <div className='m-5' key={index}>
                                                <Card sx={{minWidth: 370,borderRadius:"30px" }} className='fn-crd' onClick={() => handleClick(it.id, 'CinniPedestalFan', it.name)}>
                                                    <CardHeader sx={{color:"#00558C"}}
                                                        title={`Cinni ${it.name}`}
                                                    />
                                                   
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={it.image.card}
                                                        alt={it.name}
                                                        className='img-mx-length'
                                                    />
                                                   
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <ul className='cinni-desc-list'>
                                                                <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                                <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                                <li>VARIENT: {it.color.map((colName, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index}>{colName}, </span>
                                                                        </>
                                                                    );
                                                                })}
                                                                </li>
                                                            </ul>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='see-more-btn'>
                                <div className='fan-card'>
                                    <Button variant="outlined" onClick={navigateToPedestalFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}} >
                                        <span className='explore-btn'>Explore</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Container>
                    
                    { /* ---------------------------------- Mini Pedestal Fan ---------------------------------------- */}
                    <Container maxWidth='xl'>
                    <div className=''>
                        <div className='fan-heading'>
                            <p>Mini Pedestal <span>Fan</span></p>
                        </div>
                        <div className=''>
                            <div className='fan-card-container fx-wrp'>
                                {cinniMiniPedestalFanData.slice(1, 4).map((it, index) => {
                                    return (
                                        <>
                                            <div className='m-5' key={index}>
                                                <Card sx={{ minWidth: 370,borderRadius:"30px" }} className='fn-crd' onClick={() => handleClick(it.id, 'CinniMiniPedestalFan', it.name)}>
                                                    <CardHeader sx={{color:"#00558C"}}
                                                        title={`Cinni ${it.name}`}
                                                    />
                                                   
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={it.image.card}
                                                        alt={it.name}
                                                        className='img-mx-length'
                                                    />
                                                   
                                                    <CardContent>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <ul className='cinni-desc-list'>
                                                                <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                                <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                                <li>VARIENT: {it.color.map((colName, index) => {
                                                                    return (
                                                                        <>
                                                                            <span key={index}>{colName}, </span>
                                                                        </>
                                                                    );
                                                                })}
                                                                </li>
                                                            </ul>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='see-more-btn'>
                                <div className='fan-card'>
                                    <Button variant="outlined" onClick={navigateToMiniPedestalFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}} >
                                        <span className='explore-btn'>Explore</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Container>
                </div>
                <div className='cinni'>
                    <img src={Cinni} alt='cinni-logo' style={{mixBlendMode:"multiply"}}/>
                    <p>Shree Maya Fans is Authorized Manufacturer and Marketer of Cinni Fans</p>
                </div>
            </div>
        </>
    );
}

export { CinniFans };