import {
    Breeze_Front,
    CG_24_Front,
    Economy_36_Front,
    Economy_Front,
    Flora_Front,
    Supreme_Front,
    Titan_Deco_36_Front,
    Titan_Deco_Front,
    Titan_Front,
    Regular_Black_Front,
    Regular_White_Front,
} from '../../images/cinniCeilingFan';

export const cinniCeilingFanData = [
    {
        id: 1,
        image: {
            front: Regular_Black_Front,
            back: Regular_Black_Front,
            left: Regular_Black_Front,
            right: Regular_Black_Front,
        },
        name: 'Regular',
        sweepSize: '1200',
        rpm: '400',
        power: '65',
        powerSource: 'Electricity',
        color: ['White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed motor for high Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Stylish AL Bottom Cover with Powder Coated Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 2,
        image: {
            front: Regular_White_Front,
            back: Regular_White_Front,
            left: Regular_White_Front,
            right: Regular_White_Front,
        },
        name: 'Regular',
        sweepSize: '1200',
        rpm: '400',
        power: '65',
        powerSource: 'Electricity',
        color: ['White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed motor for high Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Stylish AL Bottom Cover with Powder Coated Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 3,
        image: {
            front: Titan_Front,
            back: Titan_Front,
            left: Titan_Front,
            right: Titan_Front,
        },
        name: 'Titan',
        sweepSize: '1200',
        rpm: '400',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Heavy Motor in its Category for Higher Durability',
            'GP Blades with unique Twist angle to deliver higher air',
            'Superior Powder Coating Paint Finish',
            'Double Ball Bearing for smooth & noiseless Operation',
        ],
    },
    {
        id: 4,
        image: {
            front: Economy_Front,
            back: Economy_Front,
            left: Economy_Front,
            right: Economy_Front,
        },
        name: 'Economy',
        sweepSize: '1200',
        rpm: '380',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Heavy Motor in its Category for Higher Durability',
            'GP Blades with unique Twist angle to deliver higher air',
            'Superior Powder Coating Paint Finish',
            'Double Ball Bearing for smooth & noiseless Operation',
        ],
    },
    {
        id: 5,
        image: {
            front: Flora_Front,
            back: Flora_Front,
            left: Flora_Front,
            right: Flora_Front,
        },
        name: 'Flora',
        sweepSize: '1200',
        rpm: '380',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for High Air Delivery & Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Stylish C.I. Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 6,
        image: {
            front: Titan_Deco_Front,
            back: Titan_Deco_Front,
            left: Titan_Deco_Front,
            right: Titan_Deco_Front,
        },
        name: 'Titan Deco',
        sweepSize: '1200',
        rpm: '400',
        power: '75',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Designer Trims and Bottom Cover Trim adds to the Decor',
            'Powder Coating provide longer life',
            'Full Copper Motor for Durability & High Speed',
            'Galvanized Blades for High Air Thrust',
        ],
    },
    {
        id: 7,
        image: {
            front: Supreme_Front,
            back: Supreme_Front,
            left: Supreme_Front,
            right: Supreme_Front,
        },
        name: 'Supreme',
        sweepSize: '1200',
        rpm: '360',
        power: '50',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for High Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Stylish Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 8,
        image: {
            front: Titan_Deco_Front,
            back: Titan_Deco_Front,
            left: Titan_Deco_Front,
            right: Titan_Deco_Front,
        },
        name: 'Titan Deco 50',
        sweepSize: '1200',
        rpm: '360',
        power: '50',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Elegant look with Designer Trims',
            'Wide Blades for Higher Air Delivery',
            'Full Copper Motor & Aluminium Bladed for Durability',
            'Superior Paint Finish for longer life & impeccable feel',
        ],
    },
    {
        id: 9,
        image: {
            front: CG_24_Front,
            back: CG_24_Front,
            left: CG_24_Front,
            right: CG_24_Front,
        },
        name: 'CG 24',
        sweepSize: '600',
        rpm: '750',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery & Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'Glossy Powder Coating Paint Finish for Longer Life',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 10,
        image: {
            front: Breeze_Front,
            back: Breeze_Front,
            left: Breeze_Front,
            right: Breeze_Front,
        },
        name: 'Breeze',
        sweepSize: '600',
        rpm: '760',
        power: '65',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery and Air Thrust',
            'Aluminium Blades for High Air Thrust',
            'C.I. Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 11,
        image: {
            front: Titan_Deco_36_Front,
            back: Titan_Deco_36_Front,
            left: Titan_Deco_36_Front,
            right: Titan_Deco_36_Front,
        },
        name: 'Titan Deco 36',
        sweepSize: '900',
        rpm: '460',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Stylish Bottom Cover with Powder Coating Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },
    {
        id: 12,
        image: {
            front: Economy_36_Front,
            back: Economy_36_Front,
            left: Economy_36_Front,
            right: Economy_36_Front,
        },
        name: 'Economy 36',
        sweepSize: '900',
        rpm: '460',
        power: '70',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'High Speed Motor for Higher Air Delivery & Air Thrust',
            'Galvanized Blades for High Air Thrust',
            'Powder Coating for Superior Paint Finish',
            'Full Copper Motor for Durability',
        ],
    },

    {
        id: 13,
        image: {
            front: Titan_Front,
            back: Titan_Front,
            left: Titan_Front,
            right: Titan_Front,
        },
        name: 'Titan 50',
        sweepSize: '1200',
        rpm: '360',
        power: '50',
        powerSource: 'Electricity',
        color: ['Brown', 'Ivory', 'White', 'Black'],
        numberOfBlades: '3',
        warrenty: '2',
        features: [
            'Heavy Motor in its Category for Higher Durability',
            'Aluminium Blades with unique Twist angle to deliver higher air',
            'Superior Powder Coating Paint Finish',
            'Double Ball Bearing for smooth & noiseless Operation',
        ],
    },
];