import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import { Green_border } from "../../../images/icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import emailjs from "emailjs-com";

function ContactUs(props) {
  const theme = useTheme();
  const stylesheet = useMediaQuery(theme.breakpoints.between("xs", "2500"));
  const mediaquery = useMediaQuery(theme.breakpoints.between("0",'550'))
  const form = useRef();

  const productImg = props.prodImg;
  const productName = props.prodName;
  const productDesc = props.prodDesc;
  const prodCategory = props.prodCategory;

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    date:"",
    description: "",
  };

  const sendEmail = (e) => {
    e.preventDefault();

    //@Note:-
    //at the time of adding shree maya fan email address, move send form values to .env file for security

    emailjs
      .sendForm(
        "service_lfod7v9",
        "template_e9lm519",
        form.current,
        "iWJM9vBQf33tUQMzx"
      )
      .then(
        (result) => {
          console.log("result", result.text);
        },
        (error) => {
          console.log("error", error.text);
        }
      );
  };

  const title = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "24px",
      fontWeight: "500",
      color: "#00558C",
    }),
  };
  const content = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "64px",
      fontWeight: "600",
    }),
  };
  const text = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "26px",
      fontWeight: "400",
      color: "#333",
    }),
  };
  const formTitle = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "25px",
      fontWeight: "700",
      color: "#282C4B",
    }),
  };

  const formLabel = {
    ...(stylesheet && {
      fontFamily: "Outfit",
      fontSize: "15px",
      fontWeight: "500",
      color: "#333",
      marginTop: "1em",
      marginBottom: "1em",
    }),
  };
  
  const ResponsiveBtn = {
    ...(mediaquery&&{
      borderRadius:"15px",
      width: "150px",
      height: "40px",
    })
  }
  const txtResp = {
    ...(mediaquery&&{
      fontSize:"14px"
    })
  }

  return (
    <>
      <Container maxWidth="xl" id="contact">
        <Box className="contact-us-container">
          <Box className="contact-us-contentBox">
            <Box className="contact-us-content">
              <Box>
                <img src={Green_border} alt="" />
              </Box>
              <Box>
                <Typography sx={{ ...title }}>Contact Us</Typography>
                <Typography sx={{ ...content }}>
                  <span style={{ color: "#CDCDCD" }}>
                    Take the First Step Towards
                  </span>{" "}
                  <span style={{ color: "#00558C" }}>
                    Better Cooling Experience
                  </span>
                </Typography>
                <Typography sx={{ ...text }}>
                  Get in Touch with Us Now!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="contact-us-formBox">
            <Typography sx={{ ...formTitle }}>
              Let's Collaborate Now!
            </Typography>
            <Formik initialValues={initialValues}>
              {(props) => (
                <Form ref={form} onSubmit={sendEmail}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={5}>
                      <Typography sx={{ ...formLabel }}>Full Name</Typography>
                      <Field
                        as={TextField}
                        id="outlined-required"
                        name="name"
                        label="Input Your Name"
                        fullWidth
                        error={props.errors.name && props.touched.name}
                        helperText={<ErrorMessage name="name" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Typography sx={{ ...formLabel }}>Email</Typography>
                      <Field
                        as={TextField}
                        name="email"
                        label="Input Your Email"
                        fullWidth
                        error={props.errors.email && props.touched.email}
                        helperText={<ErrorMessage name="email" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",                            fontWeight: "400",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} mt={2}>
                      <Typography sx={{ ...formLabel }}>Phone No.</Typography>
                      <Field
                        as={TextField}
                        name="phoneNumber"
                        label="Input Your Phone No."
                        fullWidth
                        error={
                          props.errors.phoneNumber && props.touched.phoneNumber
                        }
                        helperText={<ErrorMessage name="phoneNumber" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                          },
                        }}
                        InputLabelProps={{style:{
                            fontFamily:"Outfit",
                            fontSize:10
                        }}}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} mt={2}>
                      <Typography sx={{ ...formLabel }}>Date</Typography>
                      <Field
                        as={TextField}
                        name="date"
                        type="date"
                        fullWidth
                        error={props.errors.address && props.touched.address}
                        helperText={<ErrorMessage name="address" />}
                        InputProps={{
                          style: {
                            backgroundColor: "#FEFBE1",
                            borderRadius: "0",
                            fontFamily: "Outfit",
                            fontSize: "10px",
                            fontWeight: "400",
                          },
                        }}
                        sx={{ "& fieldset": { border: "none" } }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Typography sx={{ ...formLabel }}>Message</Typography>
                      <Field
                        as={TextareaAutosize}
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        error={
                          props.errors.description && props.touched.description
                        }
                        helperText={<ErrorMessage name="description" />}
                        style={{
                          border: "0",
                          backgroundColor: "#FEFBE1",
                          outline: "0",
                          padding: "1em 1em",
                          resize: "none",
                          width: "100%",
                          height: "94px",
                          fontFamily: "Outfit",
                          fontSize:"18px"
                        }}
                        className="text-area"
                        placeholder="Write Message"
                        required
                      />
                    </Grid>
                  </Grid>

                  <input name="fanName" type="hidden" value={productName} />
                  <input
                    name="fanCategory"
                    type="hidden"
                    value={prodCategory}
                  />

                  <Button
                    type="submit"
                    className="btnStyle"
                    variant="contained"
                    sx={{
                      "&:hover": { background: "transparent", boxShadow: "none",border:"1px solid #FCE302",color:"#FCE302" },
                      marginTop: "2em",
                      backgroundColor: "#FCE302",
                      width: "215px",
                      height: "53px",
                      borderRadius: "20px",
                      fontFamily: "Outfit",
                      boxShadow: "none",
                      textTransform: "lowercase",
                      ...ResponsiveBtn,
                    }}
                  >
                   <Typography sx={{
                      "&:hover":{color:"#FCE302"},
                      fontFamily:"inherit",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#333",
                      width:"inherit",
                      height:"inherit",
                      padding:"0.6em 0",
                      ...txtResp
                   }}><span style={{textTransform:"uppercase"}}>S</span>end <span style={{textTransform:"uppercase"}}>M</span>essage</Typography>
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export { ContactUs };
