import { Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Background_BrandImg } from '../../../images/background';
import { Logo_2,Logo_3,Logo_4,Logo_6 } from '../../../images/brands';


function OurBrands() {
    const theme = useTheme();
    const stylesheet = useMediaQuery(theme.breakpoints.between("xs",'2500'));

    const title = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"32px",
            fontWeight:"400",
            textAlign:"center",
            color:"#333"
        })
    }
    return (
        <div style={{backgroundImage:`url(${Background_BrandImg})`,margin:"0 0 100px 0",backgroundRepeat:"no-repeat",backgroundSize:"100% 300px"}} className='brands-container'>
           <Box sx={{padding:"2em 5em"}}>
           <Typography sx={{...title}}>Our Brands</Typography>
            <Box className="brands-flexBox">
                 <Box className="brands-box">
                 <img src={Logo_2}/>
                 </Box>
                 <Box className="brands-box">
                 <img src={Logo_3}/>
                 </Box>
                 <Box className="brands-box">
                 <img src={Logo_4}/>
                 </Box>
                 <Box className="brands-box">
                 <img src={Logo_6}/>
                 </Box>
            </Box>
           </Box>
        </div>
    )
}

export {OurBrands}