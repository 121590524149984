import {
    Coolster_Front_Card,
    Coolster_Front,
    Coolster_Back,
    Coolster_Left,
    Coolster_Right,

    Delux_Front_Card,
    Delux_Front,
    Delux_Back,
    Delux_Left,
    Delux_Right,

    Gold_Front_Card,
    Gold,
    
    Jayco_2_Front_Card,
    Jayco_2_Front,
    Jayco_2_Back,
    Jayco_2_Left,
    Jayco_2_Right,

    Jayco_Front_Card,
    Jayco_Front,
    Jayco_Back,
    Jayco_Left,
    Jayco_Right,

    Kisan_Front_Card,
    Kisan_Front,
    Kisan_Back,
    Kisan_Left,
    Kisan_Right,

    New_Super_Front_Card,
    New_Super_Front,
    New_Super_Back,
    New_Super_Left,
    New_Super_Right,

    Power_dlx_Front_Card,
    Power_dlx,
    
    Power_Front_Card,
    Power_Front,
    Power_Back,
    Power_Left,
    Power_Right,

    Square_Front_Card,
    Square_Front,
    Square_Back,
    Square_Left,
    Square_Right,

    Super_CI_Front_Card,
    Super_CI_Front,
    Super_CI_Back,
    Super_CI_Left,
    Super_CI_Right,

    Super_Front_Card,
    Super_Front,
    Super_Back,
    Super_Left,
    Super_Right,

    Twister_Front_Card,
    Twister_Front,
    Twister_Back,
    Twister_Left,
    Twister_Right,

    Cooler_Kit_Front_Card,
    Cooler_Kit_Front,
    Cooler_Kit_Back,
    Cooler_Kit_Left,
    Cooler_Kit_Right,
} from '../../images/pedestalFan';

export const pedestalFanData = [
    {
        id: 1,
        image: {
            card:Super_Front_Card,
            front: Super_Front,
            back: Super_Back,
            left: Super_Left,
            right: Super_Right,
        },
        name: 'Super',
        description: 'Super comes with 3 Patti Farrata Blade & 16 No. 12 Patti Farrata Jali for High Air Delivery and Full Copper Motor with 19mm (108mm core) Maruti Laminations and 13" Sheet Base Heavy.',
        sweepSize: '450',
        rpm: '2250',
        power: '120',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '3 Patti Farrata Blade',
            '13" Sheet Base Heavy',
            'Full Copper Motor with 19mm (108mm core) Maruti Laminations',
            '16 No. 12 Patti Farrata Jali',
            'Also available in Aluminium Winding',
        ],
    },
    {
        id: 2,
        image: {
            card: Super_CI_Front_Card,
            front: Super_CI_Front,
            back: Super_CI_Back,
            left: Super_CI_Left,
            right: Super_CI_Right,
        },
        name: 'Super C.I.',
        description: 'Super C.I. comes with 3 Patti Farrata Blade & 16 No. 12 Patti Farrata Jali for High Air Delivery and Full Copper Motor with 19mm (108mm core) Maruti Laminations and C.I. Base Medium.',
        sweepSize: '450',
        rpm: '2250',
        power: '120',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '3 Patti Farrata Blade',
            '13" Sheet Base Heavy',
            'Full Copper Motor with 19mm (108mm core) Maruti Laminations',
            '16 No. 12 Patti Farrata Jali',
            'Also available in Aluminium Winding',
        ],
    },
    {
        id: 3,
        image: {
            card: Jayco_Front_Card,
            front: Jayco_Front,
            back: Jayco_Back,
            left: Jayco_Left,
            right: Jayco_Right,
        },
        name: 'Jayco',
        description: 'Jayco comes with 2 Patti AL Farrata Blade & Heavy  16 NO. 21” 12 Patti Farrata Jali and Copper Motor with 25mm AL CRC Laminations and 15" Sheet Base Heavy with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2600',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti Farrata Blade',
            '15" Sheet Base Heavy with Rubber Fittings',
            'Full Copper Motor with 25mm AL CRC Laminations',
            'Heavy  16 NO. 21” 12 Patti Farrata Jali',
        ],
    },
    {
        id: 4,
        image: {
            card: Jayco_2_Front_Card,
            front: Jayco_2_Front,
            back: Jayco_2_Back,
            left: Jayco_2_Left,
            right: Jayco_2_Right,
        },
        name: 'Jayco',
        description: 'Jayco comes with 2 Patti AL Farrata Blade & 135 Wire Powder Coated Tiger Jali and Copper Motor with 25mm AL CRC Laminations and 15" Sheet Base Heavy with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2650',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti Die Casted Farrata Blade',
            '15" Sheet Base Heavy with Rubber Fittings',
            'Full Copper Motor with 25mm AL CRC Laminations',
            '135 Wire Powder Coated Tiger Jali',
        ],
    },
    {
        id: 5,
        image: {
            card: Kisan_Front_Card,
            front: Kisan_Front,
            back: Kisan_Back,
            left: Kisan_Left,
            right: Kisan_Right,
        },
        name: 'Kisan',
        description: 'Kisan comes with 2 Patti Die Casted Farrata Blade & Heavy 15 No. 12 Patti Farrata Jali and Copper Motor with 25mm CRC Laminations and 16" Heavy Sheet Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti Die Casted Farrata Blade',
            '16" Heavy Sheet Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            'Heavy 16 No. 21" 12 Patti Farrata Jali',
        ],
    },
    {
        id: 6,
        image: {
            card: New_Super_Front_Card,
            front: New_Super_Front,
            back: New_Super_Back,
            left: New_Super_Left,
            right: New_Super_Right,
        },
        name: 'New Super',
        description: 'New Super comes with 2 Patti Die Casted Farrata Blade & Heavy 16 No. 21" 12 Patti Farrata Jali and Copper Motor with 19mm CRC Laminations and 15" Heavy Sheet Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2580',
        power: '105',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti Die Casted Farrata Blade',
            '15" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 16 No. 21" 12 Patti Farrata Jali',
        ],
    },
    {
        id: 7,
        image: {
            card: Power_Front_Card,
            front: Power_Front,
            back: Power_Back,
            left: Power_Left,
            right: Power_Right,
        },
        name: 'Power',
        description: 'Power comes with 2 Patti AL Die casted Farrata Blade & 135 Wire Powder Coated Tiger Farrata Jali and Copper Motor with 25mm CRC Laminations and 15" Heavy Sheet Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti AL Die casted Farrata Blade',
            '15" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Powder Coated Tiger Farrata Jali',
        ],
    },
    {
        id: 8,
        image: {
            card: Delux_Front_Card,
            front: Delux_Front,
            back: Delux_Back,
            left: Delux_Left,
            right: Delux_Right,
        },
        name: 'Deluxe',
        description: 'Deluxe comes with 4 Patti AL Sheet Farrata Blade & 13 No. 12 Patti Farrata Jali with High Speed Box Motor and Copper Motor with 25mm (108mm core) Maruti Laminations and C.I. Base Heavy.',
        sweepSize: '450',
        rpm: '2410',
        power: '130',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '4 Patti AL Sheet Farrata Blade',
            'C.I. Base Heavy',
            'Full Copper Motor with 25mm (108mm core) Maruti Laminations',
            '13 No. 12 Patti Farrata Jali',
        ],
    },
    {
        id: 9,
        image: {
            card: Square_Front_Card,
            front: Square_Front,
            back: Square_Back,
            left: Square_Left,
            right: Square_Right,
        },
        name: 'Square',
        description: 'Square comes with 3 Patti AL Sheet Farrata Blade & 16 No. 12 Patti Farrata Jali with High Speed Box Motor and Copper Motor with 19mm (108mm Core) Maruti Laminations and Cross Base Heavy.',
        sweepSize: '450',
        rpm: '2250',
        power: '125',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Box Motor',
            'Higher Air Delivery & Air Thrust',
            '3 Patti AL Sheet Farrata Blade',
            'Square Base Heavy',
            'Full Copper Motor with 19mm (108mm Core) Maruti Laminations',
            '16 No. 12 Patti Farrata Jali',
            'Also available in Aluminium Winding',
        ],
    },
    {
        id: 10,
        image: {
            card: Power_dlx_Front_Card,
            front: Power_dlx,
            back: Power_dlx,
            left: Power_dlx,
            right: Power_dlx,
        },
        name: 'Power DLX',
        description: 'Power DLX comes with 2 Patti AL Die Casted Farrata Blade & 135 Wire Nickel Chrome Coated Tiger Jali and Copper Motor with 25mm CRC Laminations and 15" Heavy Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '2 Patti AL Die Casted Farrata Blade',
            '15" Sheet Base Heavy with Rubber Fitting',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Nickel Chrome Tiger Jali',
        ],
    },
    {
        id: 11,
        image: {
            card: Gold_Front_Card,
            front: Gold,
            back: Gold,
            left: Gold,
            right: Gold,
        },
        name: 'Gold',
        description: 'Gold comes with Aerodynamic Fibre 3 Blade & 135 Wire Nickel Chrome Coated Tiger Jali and Copper Motor with 25mm CRC Laminations and 16" Heavy Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '2300',
        power: '120',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '3 Aero Design Fibre Farrata Blade',
            '16" Sheet Base Heavy with Rubber Fitting',
            'Full Copper Motor with 25mm CRC Laminations',
            'Tiger Nickel Farrata Jali',
        ],
    },
    {
        id: 12,
        image: {
            card: Coolster_Front_Card,
            front: Coolster_Front,
            back: Coolster_Back,
            left: Coolster_Left,
            right: Coolster_Right,
        },
        name: 'Coolster',
        description: 'Coolster comes with Aerodynamic AL 3 Blade & 120 Wire Powder Coated Tiger Jali and Full Copper Motor with 1" CRC Laminations and 16" Sheet Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '1400',
        power: '100',
        powerSource: 'Electricity',
        color: ['Red'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade',
            'Also Available in 5 Patti Fibre Blade',
            '16" Sheet Base with Rubber Fitting',
            'Full Copper Motor with 1" CRC Laminations',
            '120 Wire Powder Coated Tiger Jali',
            'Also available in Aluminium Winding',
        ],
    },
    {
        id: 13,
        image: {
            card: Twister_Front_Card,
            front: Twister_Front,
            back: Twister_Back,
            left: Twister_Left,
            right: Twister_Right,
        },
        name: 'Twister',
        description: 'Twister comes with Aerodynamic AL 3 Blade & 135 Wire Nickel Chrome Coated Tiger Jali and Copper Motor with 1" CRC Laminations and 16" Heavy Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '1400',
        power: '100',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '16" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 1" CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },

    {
        id: 14,
        image: {
            card: Cooler_Kit_Front_Card,
            front: Cooler_Kit_Front,
            back: Cooler_Kit_Back,
            left: Cooler_Kit_Left,
            right: Cooler_Kit_Right,
        },
        name: 'Cooler Kit',
        description: 'Cooler Kit comes with 5 Aero Design Fibre Farrata Blade & 135 Wire Powder Coated Tiger Farrata Jali and Copper Motor with 25mm CRC Laminations and 17" Sheet Heavy Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '1250',
        power: '115',
        powerSource: 'Electricity',
        color: ['Red'],
        numberOfBlades: '5',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '5 Aero Design Fibre Farrata Blade',
            '17" Sheet Heavy Base with Rubber Fittings',
            '25mm (108mm core) Maruti Laminations',
            '135 Wire Powder Coated Tiger Farrata Jali',
        ],
    },
];