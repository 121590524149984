import { Box, Card, CardActions, CardContent, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { TableFan_gif } from "../../../images/icons";
import { NavLink } from "react-router-dom";


function OurProductList() {
    const cardData = [
         {id:0,title:"Ceiling Fans",btn:"Choose Your Style",path:"ceilingFan"},
         {id:1,title:"Pedestal Fans",btn:"Choose Your Style",path:"pedestalFan"},
         {id:2,title:"Table Fans",btn:"Choose Your Style",path:"tableFan"},
         {id:3,title:"Small Fans",btn:"Choose Your Style",path:"smallFan"},
         {id:4,title:"Mini Pedestal Fans",btn:"Choose Your Style",path:"miniPedestalFan"}
    ]
    
    const theme = useTheme();
    const stylesheet = useMediaQuery(theme.breakpoints.between('xs','2500'))
    
    const card = {
        ...(stylesheet && {
            background:"#00558C",
            textAlign:"center",
            padding:'2em 1em',
            borderRadius:"20px"
           
        })
    }
    const title = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:'31px',
            color:"#fff",
            fontWeight:"300"
      })
    }
    const cardImg = {
        ...(stylesheet && {
            boxShadow:"none",
            background:"transparent"
        })
    }
  

    return (
        <Box>
           <Grid container spacing={{xs:2,md:6}} columns={{xs:4,sm:6,md:8,lg:8,xl:12}} 
           columnSpacing={{lg:-25,xl:-25,md:-10}} alignItems='center'
           >   
               <Card sx={{...cardImg}}>
                 <CardContent>
                    <img style={{width:"240px",height:"249px"}} src={TableFan_gif} alt="Mayatable-fan-gif"/>
                 </CardContent>
                </Card>  
                   
               {cardData?.map((item)=>{
                   return(
                    <Grid item xs={6} sm={6} md={4} key={item.id}>
                     <Card sx={{maxWidth:325,...card}}>
                        <CardContent>
                            <Typography sx={{...title}}>{item.title}</Typography>
                        </CardContent>
                        <CardActions>
                               <Box className="nav-btn">
                               <NavLink to={`/${item.path}`}>{item.btn}</NavLink>
                               </Box>
                        </CardActions>
                     </Card>
                    </Grid>
                   )
               })}
           </Grid>
        </Box>
    )
}

export {OurProductList}