import React from 'react';
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardMedia, Typography, CardActionArea, Button, Divider, Container } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ceilingFanData } from '../../_helpers/data/CeilingFanData';
import { tableFanData } from '../../_helpers/data/TableFanData';
import { smallFanData } from '../../_helpers/data/SmallFanData';
import { pedestalFanData } from '../../_helpers/data/PedestalFanData';
import { miniPedestalFanData } from '../../_helpers/data/MiniPedestalFanData';
import { verticalBg } from '../../images/background';

function Product() {
    const navigate = useNavigate();
    const navigateToCeilingFan = () => navigate('/ceilingFan'); //navigate to ceilingFan page
    const navigateToTableFan = () => navigate('/tableFan'); //navigate to tableFan page
    const navigateToSmallFan = () => navigate('/smallFan'); //navigate to smallFan page
    const navigateToPedestalFan = () => navigate('/pedestalFan'); //navigate to pedestalFan page
    const navigateToMiniPedestalFan = () => navigate('/miniPedestalFan'); //navigate to miniPedestalFan page

    const handleClick = (status, method, fanName) => {
        console.log('handleClick', status, method);
        navigate(`/productDetails/${method}/${fanName}`, { state: { key_id: status, type: method } });
    }

    return (
        <>
            <div style={{background:`url(${verticalBg})`,backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
            { /* ---------------------------------- Ceiling Fan ---------------------------------------- */}
            <Container maxWidth='xl'>
                <div className='fan-heading'>
                   <p>Ceiling Fans</p>
                </div>
                <div className='fan-width-container'>
                    <div className='fan-card-container fx-wrp'>
                        {ceilingFanData.slice(3, 6).map((it, index) => {
                            return (
                                <>
                                    <div className='m-5' key={index}>
                                        <Card sx={{ maxWidth: 370,borderRadius:"30px"}} onClick={() => handleClick(it.id, 'CeilingFan', it.name)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="240"
                                                    image={it.image.card}
                                                    alt={it.name}
                                                    className='img-mx-length'
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div" 
                                                    sx={{color:"#00558C",
                                                    fontFamily:"Outfit",
                                                    fontSize:"24px",
                                                    fontWeight:"500"
                                                   }}
                                                    >
                                                        {it.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary"
                                                     sx={{color:"#333",
                                                     fontFamily:"Outfit",
                                                     fontSize:"18px",
                                                     fontWeight:"400"
                                                    }}
                                                    >
                                                        {it.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='see-more-btn'>
                        <div className='fan-card'>
                            <Button variant="outlined" onClick={navigateToCeilingFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                               <Typography><span className='exp-btn'>E</span>xplore</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
               
            </Container>

            { /* ---------------------------------- Table Fan ---------------------------------------- */}
            <Container maxWidth='xl'>
                <div className='fan-heading'>
                    <p>Table <span>Fan</span></p>
                </div>
                <div className='fan-width-container'>
                    <div className='fan-card-container fx-wrp'>
                        {tableFanData.map((it, index) => {
                            return (
                                <>
                                    <div className='m-5' key={index}>
                                        <Card sx={{ maxWidth: 370,borderRadius:"30px"}} onClick={() => handleClick(it.id, 'TableFan', it.name)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="240"
                                                    image={it.image.card}
                                                    alt={it.name}
                                                    className='img-mx-length'
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div"
                                                    sx={{color:"#00558C",
                                                    fontFamily:"Outfit",
                                                    fontSize:"24px",
                                                    fontWeight:"500"
                                                }}
                                                    >
                                                        {it.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" 
                                                    sx={{color:"#333",
                                                     fontFamily:"Outfit",
                                                     fontSize:"18px",
                                                     fontWeight:"400"
                                                     }}
                                                    >
                                                        {it.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='see-more-btn'>
                        <div className='fan-card'>
                            <Button variant="outlined" onClick={navigateToTableFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                            <Typography><span className='exp-btn'>E</span>xplore</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            { /* ---------------------------------- Small Fan ---------------------------------------- */}
            <Container maxWidth='xl'>
                <div className='fan-heading'>
                    <p>Small <span>Fan</span></p>
                </div>
                <div className='fan-width-container'>
                    <div className='fan-card-container fx-wrp'>
                        {smallFanData.slice(1, 4).map((it, index) => {
                            return (
                                <>
                                    <div className='m-5' key={index}>
                                        <Card sx={{  maxWidth: 370,borderRadius:"30px" }} onClick={() => handleClick(it.id, 'SmallFan', it.name)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="240"
                                                    image={it.image.card}
                                                    alt={it.name}
                                                    className='img-mx-length'
                                                    sx={{ maxWidth: 170 }}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div"
                                                      sx={{color:"#00558C",
                                                      fontFamily:"Outfit",
                                                      fontSize:"24px",
                                                      fontWeight:"500"
                                                      }}
                                                    >
                                                        {it.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary"
                                                    sx={{color:"#333",
                                                    fontFamily:"Outfit",
                                                    fontSize:"18px",
                                                    fontWeight:"400"
                                                    }}
                                                    >
                                                        {it.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='see-more-btn'>
                        <div className='fan-card'>
                            <Button variant="outlined" onClick={navigateToSmallFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                            <Typography><span className='exp-btn'>E</span>xplore</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            { /* ---------------------------------- Pedestal Fan ---------------------------------------- */}
            <Container maxWidth='xl'>
                <div className='fan-heading'>
                    <p>Pedestal <span>Fan</span></p>
                </div>
                <div className='fan-width-container'>
                    <div className='fan-card-container fx-wrp'>
                        {pedestalFanData.slice(3, 6).map((it, index) => {
                            return (
                                <>
                                    <div className='m-5' key={index}>
                                        <Card sx={{ maxWidth: 370,borderRadius:"30px" }} onClick={() => handleClick(it.id, 'PedestalFan', it.name)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="240"
                                                    image={it.image.card}
                                                    alt={it.name}
                                                    className='img-mx-length'
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div"
                                                    sx={{color:"#00558C",
                                                    fontFamily:"Outfit",
                                                    fontSize:"24px",
                                                    fontWeight:"500"
                                                }}
                                                    >
                                                        {it.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary"
                                                      sx={{color:"#333",
                                                      fontFamily:"Outfit",
                                                      fontSize:"18px",
                                                      fontWeight:"400"
                                                  }}
                                                    >
                                                        {it.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='see-more-btn'>
                        <div className='fan-card'>
                            <Button variant="outlined" onClick={navigateToPedestalFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                            <Typography><span className='exp-btn'>E</span>xplore</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            { /* ---------------------------------- Mini Pedestal Fan ---------------------------------------- */}
            <Container maxWidth='xl' sx={{paddingBottom:'5em'}}>
                <div className='fan-heading'>
                    <p>Mini Pedestal <span>Fan</span></p>
                </div>
                <div className='fan-width-container'>
                    <div className='fan-card-container fx-wrp'>
                        {miniPedestalFanData.slice(0, 3).map((it, index) => {
                            return (
                                <>
                                    <div className='m-5' key={index}>
                                        <Card sx={{ maxWidth: 370,borderRadius:"30px" }} onClick={() => handleClick(it.id, 'MiniPedestalFan', it.name)}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="240"
                                                    image={it.image.card}
                                                    alt={it.name}
                                                    className='img-mx-length'
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div"
                                                     sx={{color:"#00558C",
                                                     fontFamily:"Outfit",
                                                     fontSize:"24px",
                                                     fontWeight:"500"
                                                 }}
                                                    >
                                                        {it.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary"
                                                     sx={{color:"#333",
                                                     fontFamily:"Outfit",
                                                     fontSize:"18px",
                                                     fontWeight:"400"
                                                 }}
                                                    >
                                                        {it.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className='see-more-btn'>
                        <div className='fan-card'>
                            <Button variant="outlined" onClick={navigateToMiniPedestalFan} sx={{"&:hover": {backgroundColor: "transparent",border:'1px solid #FCE302',color:"#FCE302" }}}>
                            <Typography><span className='exp-btn'>E</span>xplore</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
            </div>
        </>
    );
}

export { Product };