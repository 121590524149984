import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from '../../module/home';
import { Product } from '../../module/product';
import { DownloadBrochure } from '../../module/downloadBrochure';
import { CinniFans } from '../../module/cinni';
import { CeilingFan, MiniPedestalFan, PedestalFan, SmallFan, TableFan } from '../../module/fans';
import { ProductDetails } from '../../module/productDetails/ProductDetails';
import { CinniCeilingFan, CinniMiniPedestalFan, CinniPedestalFan, CinniSmallFan, CinniTableFan } from '../../module/cinniFans';
import { Header } from '../header/Header';

export const Routing = () => (
    <>
    <Header/>

        <Routes >
            <Route exact path="/" element={<Home />}></Route>
            <Route path="*" element={<Home/>}></Route>
            <Route path="/product" element={<Product />}></Route>
            <Route path="/download-brochure" element={<DownloadBrochure />}></Route>
            <Route path="/cinni" element={<CinniFans />}></Route>
            <Route exact path="/ceilingFan" element={<CeilingFan />}></Route>
            <Route exact path="/tableFan" element={<TableFan />}></Route>
            <Route exact path="/smallFan" element={<SmallFan />}></Route>
            <Route exact path="/pedestalFan" element={<PedestalFan />}></Route>
            <Route exact path="/miniPedestalFan" element={<MiniPedestalFan />}></Route>
            <Route path="/productDetails/:fanCategory/:fanName" element={<ProductDetails />}></Route>

            <Route exact path="/cinni-ceiling-fan" element={<CinniCeilingFan />}></Route>
            <Route exact path="/cinni-mini-pedestal-fan" element={<CinniMiniPedestalFan />}></Route>
            <Route exact path="/cinni-pedestal-fan" element={<CinniPedestalFan />}></Route>
            <Route exact path="/cinni-small-fan" element={<CinniSmallFan />}></Route>
            <Route exact path="/cinni-table-fan" element={<CinniTableFan />}></Route>
        </Routes>
    </>
);