import {
    Swing_3b_Front_Card,
    Swing_3b_Front,
    Swing_3b_Back,
    Swing_3b_Left,
    Swing_3b_Right,
    Swing_4b_Front_Card,
    Swing_4b_Front,
    Swing_4b_Back,
    Swing_4b_Left,
    Swing_4b_Right,
    Super_Front,
    Super_Front_Card,
} from '../../images/tableFan';

export const tableFanData = [
    {
        id: 1,
        image: {
            card:Swing_3b_Front_Card,
            front: Swing_3b_Front,
            back: Swing_3b_Back,
            left: Swing_3b_Left,
            right: Swing_3b_Right,
        },
        name: 'Swing 3B',
        description: 'Swing 3B comes with Aero Design 3 Leaf Wide AL Sheet Blade & 12 Patti Table Jali and Copper Motor with 15mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '77',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aero Design 3 Leaf Wide AL Sheet Blade',
            'Full Copper Motor with 15mm CRC Laminations',
            '12 Patti Table Jali',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 2,
        image: {
            card:Swing_4b_Front_Card,
            front: Swing_4b_Front,
            back: Swing_4b_Back,
            left: Swing_4b_Left,
            right: Swing_4b_Right,
        },
        name: 'Swing 4B',
        description: 'Swing 4B comes with Aero Design 4 Leaf Wide AL Sheet Blade & 12 Patti Table Jali and Copper Motor with 19mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aero Design 4 Leaf Wide AL Sheet Blade',
            'Full Copper Motor with 19mm CRC Laminations',
            '12 Patti Table Jali',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
    {
        id: 3,
        image: {
            card:Super_Front_Card,
            front: Super_Front,
            back: Super_Front,
            left: Super_Front,
            right: Super_Front,
        },
        name: 'Super',
        description: 'Super comes with Aero Design 3 AL Sheet Blade & 12 Patti Table Jali and Copper Motor with 15mm CRC Laminations & 90 degree Oscillation with 3 Way Speed Control.',
        sweepSize: '400',
        rpm: '2450',
        power: '75',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aero Design 3 AL Sheet Blade',
            'Full Copper Motor with 15mm CRC Laminations',
            '12 Patti Table Jali',
            '3 Way Speed Control',
            '90 degree Oscillation',
        ],
    },
];