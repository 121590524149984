import {
    Mino_Fix_Front_Card,
    Mino_Fix_Front,
    Mino_Fix_Back,
    Mino_Fix_Left,
    Mino_Fix_Right,

    Mino_Osc_Front_Card,
    Mino_Osc_Front,
    Mino_Osc_Back,
    Mino_Osc_Left,
    Mino_Osc_Right,

    Cabin_3P_Front_Card,
    Cabin_3P_Front,
    Cabin_3b_Back,
    Cabin_3b_Left,
    Cabin_3b_Right,

    Cabin_4P_Front_Card,
    Cabin_4P_Front,
    Cabin_4b_Back,
    Cabin_4b_Left,
    Cabin_4b_Right,
    
    Cabin_4b_12_inch_Back,
    Cabin_4b_12_inch_Left,
    Cabin_4b_12_inch_Right,

    Racer_Front_Card,
    Racer_Front,
    Racer_Back,
    Racer_Left,
    Racer_Right,

    Star_12_Front_Card,
    Star_12_Front,
    Star_12_Back,
    Star_12_Left,
    Star_12_Right,
} from '../../images/cinniSmallFan';

export const cinniSmallFanData = [
    {
        id: 1,
        image: {
            card: Mino_Fix_Front_Card,
            front: Mino_Fix_Front,
            back: Mino_Fix_Back,
            left: Mino_Fix_Left,
            right: Mino_Fix_Right,
        },
        name: 'Mino Fix',
        sweepSize: '250',
        rpm: '2800',
        power: '65',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 2,
        image: {
            card: Mino_Osc_Front_Card,
            front: Mino_Osc_Front,
            back: Mino_Osc_Back,
            left: Mino_Osc_Left,
            right: Mino_Osc_Right,
        },
        name: 'Mino Osc',
        sweepSize: '250',
        rpm: '2800',
        power: '65',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '90 degree Oscillation',
        ],
    },
    {
        id: 3,
        image: {
            card: Cabin_3P_Front_Card,
            front: Cabin_3P_Front,
            back: Cabin_3b_Back,
            left: Cabin_3b_Left,
            right: Cabin_3b_Right,
        },
        name: 'Cabin 3P',
        sweepSize: '400',
        rpm: '2450',
        power: '75',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 4,
        image: {
            card: Cabin_4P_Front_Card,
            front: Cabin_4P_Front,
            back: Cabin_4b_Back,
            left: Cabin_4b_Left,
            right: Cabin_4b_Right,
        },
        name: 'Cabin 4P',
        sweepSize: '400',
        rpm: '2100',
        power: '85',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 5,
        image: {
            card: 'Cabin 12"',
            front: 'Cabin 12"',
            back: Cabin_4b_12_inch_Back,
            left: Cabin_4b_12_inch_Left,
            right: Cabin_4b_12_inch_Right,
        },
        name: 'Cabin 12"',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 6,
        image: {
            card: Racer_Front_Card,
            front: Racer_Front,
            back: Racer_Back,
            left: Racer_Left,
            right: Racer_Right,
        },
        name: 'Racer',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 Degree Oscillation',
            'Metal Base and Pipe',
        ],
    },
    {
        id: 7,
        image: {
            card: Star_12_Front_Card,
            front: Star_12_Front,
            back: Star_12_Back,
            left: Star_12_Left,
            right: Star_12_Right,
        },
        name: 'Star',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '3 Way Speed Control',
            '90 Degree Oscillation',
            'Fibre Base',
        ],
    },
];