import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Cinni, Logo } from "../../images/companyLogo";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Scroll from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";


function Header() {
  const theme = useTheme();
  const stylesheet = useMediaQuery(theme.breakpoints.between("xs", "1165"));
  const stylesheet2 = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activePage, setActivePage] = useState(window.location.pathname);
  const navigate = useNavigate();
  const paths = useLocation().pathname;
  const location = paths.split("/")[1];
  const scroller = Scroll.scroller;

  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 1000,
      smooth: true,
      offset: -50,
      spy: true,
    });
    setActivePage(selector);
  };

  const handleClick = (path) => {
    setActivePage(path);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navbarMenu = {
    ...(stylesheet && {
      width: "80%",
    }),
  };

  const paper = {
    ...(stylesheet2&&{
        width:180,
        padding:0
    })
  }

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "flex", md: "flex" } }}
            >
             <NavLink to='/'>
             <img
                className="company-logo"
                src={Logo}
                alt=""
                style={{ mixBlendMode: "multiply" }}
              />
             </NavLink>
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                    width: "50%",
                    ...navbarMenu,
                  },
                }}
              >
                {location !== "product" &&
                location !== "download-brochure" &&
                location !== "ceilingFan" &&
                location !== "pedestalFan" &&
                location !== "tableFan" &&
                location !== "smallFan" &&
                location !== "miniPedestalFan" &&
                location !== "productDetails" &&
                location !== "cinni" &&
                location !== "cinni-ceiling-fan" &&
                location !== "cinni-table-fan" &&
                location !== "cinni-small-fan" &&
                location !== "cinni-pedestal-fan" &&
                location !== "cinni-mini-pedestal-fan" ? (
                  <>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        className={activePage === "/about" ? "active" : ""}
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("about")}
                      >
                        About us
                      </Link>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Link
                        to="our_product"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        className={
                          activePage === "/our_product" ? "active" : ""
                        }
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("our_product")}
                      >
                        Products
                      </Link>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Link
                        to="team"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        className={activePage === "/team" ? "active" : ""}
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("team")}
                      >
                        Team
                      </Link>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <NavLink
                        to="/download-brochure"
                        className={
                          activePage === "/download-brochure" ? "active" : ""
                        }
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("download-brochure")}
                      >
                        Brouchers
                      </NavLink>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Link
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        className={activePage === "/contact" ? "active" : ""}
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("contact")}
                      >
                        Contact Us
                      </Link>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Typography
                        className={activePage === "/about" ? "active" : ""}
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#333",
                        }}
                        onClick={() => goToPageAndScroll("about")}
                      >
                        About us
                      </Typography>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Typography
                        className={
                          activePage === "/our_product" ? "active" : ""
                        }
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#333",
                        }}
                        onClick={() => goToPageAndScroll("our_product")}
                      >
                        Products
                      </Typography>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Typography
                        className={activePage === "/team" ? "active" : ""}
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#333",
                        }}
                        onClick={() => goToPageAndScroll("team")}
                      >
                        Team
                      </Typography>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <NavLink
                        to="/download-brochure"
                        className={
                          activePage === "/download-brochure" ? "active" : ""
                        }
                        style={{ textDecoration: "none" }}
                        onClick={() => handleClick("/download-brochure")}
                      >
                        Brouchers
                      </NavLink>
                    </button>
                    <button
                      className="navlinks"
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      <Typography
                        className={activePage === "/contact" ? "active" : ""}
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#333",
                        }}
                        onClick={() => goToPageAndScroll("contact")}
                      >
                        Contact Us
                      </Typography>
                    </button>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
             <NavLink to='/cinni'>
             <img
                src={Cinni}
                alt="Logo"
                style={{ width: "125px", mixBlendMode: "multiply" }}
              />
             </NavLink>
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  
                }}
                disableScrollLock={true}
              >
                {location !== "product" &&
                location !== "download-brochure" &&
                location !== "ceilingFan" &&
                location !== "pedestalFan" &&
                location !== "tableFan" &&
                location !== "smallFan" &&
                location !== "miniPedestalFan" &&
                location !== "productDetails" && 
                location !== "cinni" && 
                location !== "cinni-ceiling-fan" &&
                location !== "cinni-table-fan" &&
                location !== "cinni-small-fan" &&
                location !== "cinni-pedestal-fan" &&
                location !== "cinni-mini-pedestal-fan" ? (
                  <>
                   <Paper sx={{width:250,maxWidth:'100%',minHeight:"auto",border:"0",boxShadow:"none",padding:"2em 0",...paper}}>
                    {/* <MenuList> */}
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block"}}
                      >
                        <Link
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={500}
                          className={activePage === "/about" ? "active" : ""}
                          style={{ textDecoration: "none" }}
                          onClick={() => handleClick("about")}
                        >
                          About us
                        </Link>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Link
                          to="our_product"
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={500}
                          className={
                            activePage === "/our_product" ? "active" : ""
                          }
                          style={{ textDecoration: "none" }}
                          onClick={() => handleClick("our_product")}
                        >
                          Products
                        </Link>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block"}}
                      >
                        <Link
                          to="team"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          className={activePage === "/team" ? "active" : ""}
                          style={{ textDecoration: "none"}}
                          onClick={() => handleClick("team")}
                        >
                          Team
                        </Link>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <NavLink
                          to="/download-brochure"
                          className={
                            activePage === "/download-brochure" ? "active" : ""
                          }
                          style={{ textDecoration: "none" }}
                          onClick={() => handleClick("download-brochure")}
                        >
                          Brouchers
                        </NavLink>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Link
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          className={activePage === "/contact" ? "active" : ""}
                          style={{ textDecoration: "none" }}
                          onClick={() => handleClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </button>
                    </MenuItem>
                    <MenuItem>
                    <NavLink to='/cinni'>
                    <img
                        src={Cinni}
                        alt="Logo"
                        style={{ width: "125px", mixBlendMode: "multiply" }}
                      />
                    </NavLink>
                    </MenuItem>
                    {/* </MenuList> */}
                   </Paper>
                  </>
                ) : (
                  <>
                    <Paper sx={{width:250,maxWidth:'100%',minHeight:"auto",border:"0",boxShadow:"none",padding:"2em 0"}}>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Typography
                          className={activePage === "/about" ? "active" : ""}
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#333",
                          }}
                          onClick={() => goToPageAndScroll("about")}
                        >
                          About us
                        </Typography>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Typography
                          className={
                            activePage === "/our_product" ? "active" : ""
                          }
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#333",
                          }}
                          onClick={() => goToPageAndScroll("our_product")}
                        >
                          Products
                        </Typography>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Typography
                          className={activePage === "/team" ? "active" : ""}
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#333",
                          }}
                          onClick={() => goToPageAndScroll("team")}
                        >
                          Team
                        </Typography>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <NavLink
                          to="/download-brochure"
                          className={
                            activePage === "/download-brochure" ? "active" : ""
                          }
                          style={{ textDecoration: "none" }}
                          onClick={() => handleClick("/download-brochure")}
                        >
                          Brouchers
                        </NavLink>
                      </button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem sx={{padding:"1em",width:"100%"}}>
                      <button
                        className="navlinks navlinksV2"
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        <Typography
                          className={activePage === "/contact" ? "active" : ""}
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#333",
                          }}
                          onClick={() => goToPageAndScroll("contact")}
                        >
                          Contact Us
                        </Typography>
                      </button>
                    </MenuItem>
                    <MenuItem>
                    <NavLink to='/cinni'>
                    <img
                        src={Cinni}
                        alt="Logo"
                        style={{ width: "125px", mixBlendMode: "multiply",marginLeft:"-75px" }}
                      />
                    </NavLink>
                    </MenuItem>
                    </Paper>
                  </>
                )}
              </Menu>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export { Header };
