import { Logo, Cinni,Cinni_v2,Maya_v2 } from '../../images/companyLogo';
import { Maya_Fans, Cinni_Fans } from '../../pdfs';

export const brochureData = [
    {
        name: 'Shree Maya Fans',
        image: Logo,
        pdfFile: Maya_Fans,
        img:Maya_v2,
    },
    {
        name: 'Cinni Fans',
        image: Cinni,
        pdfFile: Cinni_Fans,
        img:Cinni_v2,
    }
];
