import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

function ProductStatistics() {
 
    const theme = useTheme();
    const stylesheet = useMediaQuery(theme.breakpoints.between('xs','2500'))

    const text = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"24px",
            fontWeight:"500",
            color:"#333"
        })
    }

    return (
        <>
        
        <Container>
           <Box mt={5} className="our-product-list-container">
               <Box className="product-statistics-box">
                <Typography variant='h3'>25%</Typography>
                <Typography sx={{...text}}>Cost Deduction</Typography>
               </Box>
               <Box className="product-statistics-box">
               <Typography variant='h3'>10x</Typography>
                <Typography sx={{...text}}>Speedy Process</Typography>
               </Box>
               <Box className="product-statistics-box">
               <Typography variant='h3'>40%</Typography>
                <Typography sx={{...text}}>Raise in Engagement</Typography>
               </Box>
               <Box className="product-statistics-box"> 
               <Typography variant='h3'>50%</Typography>
                <Typography sx={{...text}}>Raise in conversion</Typography>
               </Box>
             </Box> 
           </Container>
        
        </>
    )
}

export {ProductStatistics}