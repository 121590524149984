import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Green_border } from "../../../images/icons";
import { OurProductList } from "./OurProductList";

function OurProduct(){
    const theme = useTheme();
    const stylesheet = useMediaQuery(theme.breakpoints.between('xs','2500'))
    const flexBox = {
        ...(stylesheet && {
            display:"flex",
            padding:"2em",
            width:"100%",
        })
    }
    const contentBox = {
        ...(stylesheet && {
            width:"90%"
        })
    }
    const title = {
        ...(stylesheet && {
            color:"#00558C",
            fontFamily:"Outfit",
            fontSize:"24px",
            fontWeight:"500"
        })
    }
    const heading = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"64px",
            fontWeight:"600",
        })
    }
    const content = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"26px",
            fontWeight:"400",
            color:"#333",
        })
    }
    return(
        <>
        <Container maxWidth='xl' sx={{marginTop:"2em"}} id="our_product">
        <Box className="flexBox">
                 <Box>
                   <img src={Green_border} alt="green-border-icon"/>
                 </Box>
                 <Box className='contentBox'>
                 
                  <h1 className='aboutus-title'>Our Products</h1>
                  <p className='aboutus-heading'>
                 <span style={{color:"#CDCDCD"}}>We Cater to all your cooling needs</span> <span style={{color:"#00558C"}}>with a wide range of fans,</span>
                  </p>
                  <p className='aboutus-content'>
                   Including ceiling, pedestal, table, and small fans. We understand the importance of creating a comfortable and refreshing environment, and our fans are designed to provide just that for our valued fans like you.
                  </p>
                 
                 </Box>
               </Box>
               <OurProductList/>
        </Container>
        </>
    )
}

export {OurProduct}