import React from 'react';
import { MainContent } from "./layout/mainContent";

function App() {
  return (
    <MainContent />
  );
}

export default App;
