import React from 'react';
import { Hero } from './hero/Hero';
import { AboutUs } from '../aboutUs/AboutUs';
import { OurProduct } from './ourProducts/OurProduct';
import { OurTeam } from './ourTeam/OurTeam';
import { ContactUs } from './contact/ContactUs';
import { OurBrands } from './ourBrands/OurBrands';
import { bg_image } from '../../images/background';
import { ProductStatistics } from './productStatistics/ProductStatistics';

function Home() {
    return (
        <>
            <div style={{background:`url(${bg_image})`,backgroundRepeat:"no-repeat",backgroundSize:"100% 108%",backgroundPosition:"top"}}>
            <Hero/>
            <AboutUs/>
            <OurProduct/>
            </div>
            <ProductStatistics/>
            <OurTeam/>
            <ContactUs/>
            <OurBrands/>
        </>
    );
}

export { Home };