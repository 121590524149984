import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { ceilingFanData } from "../../../_helpers/data";
import Popover from "@mui/material/Popover";
import { SocialMedia } from "../../share/SocialMedia";
import { verticalBg } from "../../../images/background";


function CeilingFan() {
  const navigate = useNavigate();

  const handleClick = (status, method, fanName) => {
    console.log("handleClick", status, method);
    navigate(`/productDetails/${method}/${fanName}`, {
      state: { key_id: status, type: method },
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    console.log("event", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      style={{
        background: `url(${verticalBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Container className="bg-color" maxWidth="xl">
        <div className="fan-heading">
          <p>Ceiling Fan</p>
        </div>
        <div className="fan-card-container fx-wrp">
          {ceilingFanData.map((it, index) => {
            return (
              <>
                <div className="m-5">
                  <Card
                    sx={{ maxWidth: 370, borderRadius: "30px" }}
                    key={index}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={it.image.card}
                      alt={it.name}
                      className="img-mx-length"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          color: "#00558C",
                          fontFamily: "Outfit",
                          fontSize: "24px",
                          fontWeight: "500",
                        }}
                      >
                        {it.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "#333",
                          fontFamily: "Outfit",
                          fontSize: "18px",
                          fontWeight: "400",
                        }}
                      >
                        {it.description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ padding: "1em" }}>
                      <Button
                        size="small"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                            color: "#FCE302",
                          },
                          color: "#333",
                          background: "#FCE302",
                          borderRadius: "20px",
                          padding: "0.5em",
                          width: "100px",
                          textTransform: "lowercase",
                          fontFamily:"Outfit"
                        }}
                        onClick={handlePopoverClick}
                      >
                        <Typography sx={{fontFamily:"inherit"}}>
                          <span style={{textTransform:"uppercase"}}>S</span>hare
                        </Typography>
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                            color: "#FCE302",
                          },
                          color: "#333",
                          background: "#FCE302",
                          borderRadius: "20px",
                          padding: "0.5em",
                          width: "120px",
                          textTransform: "lowercase",
                          fontFamily:"Outfit"
                        }}
                        onClick={() =>
                          handleClick(it.id, "CeilingFan", it.name)
                        }
                      >
                        <Typography sx={{fontFamily:"inherit"}}>
                          <span style={{textTransform:"uppercase"}}>L</span>earn <span>M</span>ore
                        </Typography>
                      </Button>
                    </CardActions>
                  </Card>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: -80,
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 1 }}>
                      <SocialMedia />
                    </Typography>
                  </Popover>
                </div>
              </>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export { CeilingFan };
