import React, { useState } from "react";
import { Cinni, Maya_Logo } from "../../images/companyLogo";
import { Location_icon, Phone_icon, Mail_icon,Instagram_v2,Mail_v2,Mail_v1 } from "../../images/icons";
import { Stack, Typography } from "@mui/material";
import { Facebook_icon,Linkedin_icon,Twitter_icon } from "../../images/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import * as Scroll from "react-scroll";

function Footer() {
  const [activePage, setActivePage] = useState(window.location.pathname);
  const paths = useLocation().pathname;
  const navigate = useNavigate();
  const location = paths.split('/')[1];
  const scroller = Scroll.scroller

  const goToPageAndScroll = async(selector) =>{
    await navigate("/");
    await scroller.scrollTo(selector,{
      duration:1000,
      smooth:true,
      offset:-100,
      spy:true
    });
    setActivePage(selector)
  };

  return (
    <>
      <footer className="footer1">
        <div className="cinni-logo">
          <img src={Cinni} />
        </div>
        <div className="footer-content">
          <div className="logo">
            <img src={Maya_Logo} alt="Logo" />
            <div className="footer-links">
            <a href="https://www.linkedin.com/in/shree-maya-6935b6286/"><img src={Linkedin_icon} /></a>
            <a href="https://twitter.com/shree_maya1"><img src={Twitter_icon} /></a>
            <a href="https://www.facebook.com/shreemayafans?mibextid=LQQJ4d"><img src={Facebook_icon} /></a>
            <a href="https://instagram.com/shri_maya1993?igshid=MzRlODBiNWFlZA=="><img src={Instagram_v2} /></a>
            <a href="shrimayaelectrical@gmail.com"><img src={Mail_v1} /></a>
          </div>
          </div>
          <div className="menus menu1">
            <div className="menu-title">Menu</div>
            <ul>
            {location !== "product" && location !== "download-brochure" && location !== "ceilingFan" && location !== "pedestalFan" && location !=="tableFan" && location !=="smallFan" && location !=="miniPedestalFan" ? (
              <>
                <li>
                <Link to="about" spy={true} smooth={true} offset={-50} duration={500}
                >About Us</Link>
              </li>
              <li>
                <Link to="our_product"spy={true} smooth={true} offset={50} duration={500}>Products</Link>
              </li>
              <li>
                <Link to="team"spy={true} smooth={true} offset={50} duration={500}>Team</Link>
              </li>
              <li>
                <NavLink to="/download-brochure">Broucher</NavLink>
              </li>
              <li>
                <Link to="contact"spy={true} smooth={true} offset={50} duration={500}>Contact Us</Link>
              </li>
              </>
            ):(
              <>
               <li>
                <Typography className={activePage === "/about" ? "active" : ""}
                 sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#333",
                }}
                onClick={() => goToPageAndScroll("about")}
                >About Us</Typography>
              </li>
              <li>
                <Typography className={activePage === "/our_product" ? "active" : ""}
                 sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#333",
                }}
                onClick={() => goToPageAndScroll("our_product")}
                >Products</Typography>
              </li>
              <li>
                <Typography className={activePage === "/team" ? "active" : ""}
                 sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#333",
                }}
                onClick={() => goToPageAndScroll("team")}
                >Team</Typography>
              </li>
              <li>
                <NavLink to="/download-brochure">Broucher</NavLink>
              </li>
              <li>
                <Typography className={activePage === "/contact" ? "active" : ""}
                 sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#333",
                }}
                onClick={() => goToPageAndScroll("contact")}
                >Contact Us</Typography>
              </li>
              </>
            )}
            </ul>
          </div>
          <div className="menus menu2">
            <div className="menu-title">Follow Us</div>
            <ul>
              <li>
                <a href="https://www.linkedin.com/in/shree-maya-6935b6286/">Linkedin</a>
              </li>
              <li>
                <a href="https://twitter.com/shree_maya1">Twitter</a>
              </li>
              <li>
                <a href="https://www.facebook.com/shreemayafans?mibextid=LQQJ4d">Facebook</a>
              </li>
            </ul>
          </div>
          <div className="menus menu3">
            <div className="menu-title">Contact Us</div>
            <ul>
              <div className="contact-us-address">
                <div><img src={Location_icon} /></div>
                <div>
                  <li>
                    <a href="#link7">
                      Maya Engineering Works, Goyanka Mill Compound No. 1 Near
                      Shivpur Railway Station Shivpur, Varanasi
                    </a>
                  </li>
                </div>
              </div>
              <div className="contact-us-address">
                <div>
                  <a href="tel:9695945959"><img src={Phone_icon} /></a>
                </div>
                <div>
                  <li>
                    <a href="tel:9695945959">+91 9695945959</a>
                  </li>
                </div>
              </div>
              <div className="contact-us-address1">
                <div>
                  <a href="mailto:shrimayaelectrical@gmail.com"><img src={Mail_v2} /></a>
                </div>
                <div className="footer-email">
                  <li>
                    <a href="mailto:shrimayaelectrical@gmail.com">shrimayaelectrical@gmail.com</a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p> All right reserved by &copy;Maya Fans 2022</p>
      </div>
    </>
  );
}

export { Footer };
