import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Box, Paper } from '@mui/material';
import { brochureData } from '../../_helpers/brochure/BrochureData';
import { LandscapeBg } from '../../images/background';

function DownloadBrochure() {
    const [numPages, setNumPages] = useState(null);

    return (
        <div style={{background:`url(${LandscapeBg})`,backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"100% 100%" }}>
            <div className='download-brochure bg-color'>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                        },
                    }}
                    className='small-box'
                >
                    {
                        brochureData.map((brchr, index) => {
                            return (
                                <>
                                    <Box key={index} className='paper-wd'>
                                        <div className='brochure-name'>
                                            <img src={brchr.img} alt={brchr.name} />
                                            <a href={brchr.pdfFile} download> Download PDF</a>
                                        </div>
                                        <Document
                                            file={brchr.pdfFile}
                                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                                            className='pdf-ovrflw'
                                        >
                                            {Array.apply(null, Array(numPages))
                                                .map((x, i) => i + 1)
                                                .map(page => <Page pageNumber={page} />)}
                                        </Document>
                                    </Box>
                                </>
                            );
                        })
                    }
                </Box>
            </div>
        </div>
    );
}

export { DownloadBrochure };