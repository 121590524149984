import {
    Bullet_Front_Card,
    Bullet_Front,
    Bullet_Back,
    Bullet_Left,
    Bullet_Right,

    Cabin_3b_Front_Card,
    Cabin_3b_Front,
    Cabin_3b_Back,
    Cabin_3b_Left,
    Cabin_3b_Right,

    Cabin_4b_12_inch_Front_Card,
    Cabin_4b_12_inch_Front,
    Cabin_4b_12_inch_Back,
    Cabin_4b_12_inch_Left,
    Cabin_4b_12_inch_Right,

    Cabin_4b_Front_Card,
    Cabin_4b_Front,
    Cabin_4b_Back,
    Cabin_4b_Left,
    Cabin_4b_Right,

    Cooler_Kit_Front_Card,
    Cooler_Kit_Front,
    Cooler_Kit_Back,
    Cooler_Kit_Left,
    Cooler_Kit_Right,

    Mino_Fix_Front_Card,
    Mino_Fix_Front,
    Mino_Fix_Back,
    Mino_Fix_Left,
    Mino_Fix_Right,

    Mino_Osc_Front_Card,
    Mino_Osc_Front,
    Mino_Osc_Back,
    Mino_Osc_Left,
    Mino_Osc_Right,

    Swift_Front_Card,
    Swift_Front,
    Swift_Back,
    Swift_Left,
    Swift_Right,
} from '../../images/smallFan';

export const smallFanData = [
    {
        id: 1,
        image: {
            card:Bullet_Front_Card,
            front: Bullet_Front,
            back: Bullet_Back,
            left: Bullet_Left,
            right: Bullet_Right,
        },
        name: 'Bullet',
        description: 'Bullet comes with Aerodynamic Fibre 4 Blade & Heavy 12" Patti Spiral Guard and Copper Motor with 15mm CRC Laminations and 9" Sheet Base with Rubber Fitting.',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic Fibre 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            '9" Sheet Base with Rubber Fitting',
            'Heavy 12" Patti Spiral Guard',
        ],
    },
    {
        id: 2,
        image: {
            card:Cabin_3b_Front_Card,
            front: Cabin_3b_Front,
            back: Cabin_3b_Back,
            left: Cabin_3b_Left,
            right: Cabin_3b_Right,
        },
        name: 'Cabin 3B',
        description: 'Cabin 3B comes with Aerodynamic AL 3 Blade(Nickel Chrome) & 12 Patti Spiral Guard and Full Copper Motor with 19mm CRC Laminations for High Air Delivery and Air Thrust.',
        sweepSize: '400',
        rpm: '2450',
        power: '75',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            'Full Copper Motor with 19mm CRC Laminations',
            '12 Patti Spiral Guard',
        ],
    },
    {
        id: 3,
        image: {
            card:Cabin_4b_12_inch_Front_Card,
            front: Cabin_4b_12_inch_Front,
            back: Cabin_4b_12_inch_Back,
            left: Cabin_4b_12_inch_Left,
            right: Cabin_4b_12_inch_Right,
        },
        name: 'Cabin 4B 12"',
        description: 'Cabin 4B 12" comes with Aerodynamic AL 4 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 15mm CRC Laminations for High Air Delivery.',
        sweepSize: '250',
        rpm: '2000',
        power: '75',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 4,
        image: {
            card:Cabin_4b_Front_Card,
            front: Cabin_4b_Front,
            back: Cabin_4b_Back,
            left: Cabin_4b_Left,
            right: Cabin_4b_Right,
        },
        name: 'Cabin 4B',
        description: 'Cabin 4B comes with Aerodynamic AL 4 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Full Copper Motor with 19mm CRC Laminations for High Air Delivery & Air Thrust.',
        sweepSize: '400',
        rpm: '2450',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 5,
        image: {
            card: Cooler_Kit_Front_Card,
            front: Cooler_Kit_Front,
            back: Cooler_Kit_Back,
            left: Cooler_Kit_Left,
            right: Cooler_Kit_Right,
        },
        name: 'Cooler Kit',
        description: 'Cooler Kit comes with Aerodynamic Fibre 5 Blade & 135 Wire Spiral Guard Tiger Jali and Copper Motor with 1" AL Maruti Laminations and 17" Sheet Base with Rubber Fitting.',
        sweepSize: '450',
        rpm: '1250',
        power: '130',
        powerSource: 'Electricity',
        color: ['Red', 'Black', 'Green'],
        numberOfBlades: '5',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic Fibre 5 Blade',
            'Full Copper Motor with 1" AL Maruti Laminations',
            '17" Sheet Base with Rubber Fitting',
            '135 Wire Spiral Guard Tiger Jali',
        ],
    },
    {
        id: 6,
        image: {
            card: Mino_Fix_Front_Card,
            front: Mino_Fix_Front,
            back: Mino_Fix_Back,
            left: Mino_Fix_Left,
            right: Mino_Fix_Right,
        },
        name: 'Mino Fix',
        description: 'Mino Fix comes with Aerodynamic AL 4 Blade(Nickel Chrome) & 12 Patti Spiral Guard and Full Copper Motor with 15mm CRC Laminations for High Air Delivery and Air Thrust.',
        sweepSize: '250',
        rpm: '2800',
        power: '65',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            '12 Patti Spiral Guard',
        ],
    },
    {
        id: 7,
        image: {
            card: Mino_Osc_Front_Card,
            front: Mino_Osc_Front,
            back: Mino_Osc_Back,
            left: Mino_Osc_Left,
            right: Mino_Osc_Right,
        },
        name: 'Mino Osc',
        description: 'Mino Osc comes with Aerodynamic AL 4 Blade(Nickel Chrome) & Heavy 12 Patti Spiral Guard and Copper Motor with 15mm CRC Laminations & 90 degree Oscillation for High Air Delivery.',
        sweepSize: '250',
        rpm: '2800',
        power: '65',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 4 Blade (Nickel Chrome)',
            'Full Copper Motor with 15mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
            '90 degree Oscillation',
        ],
    },
    {
        id: 8,
        image: {
            card: Swift_Front_Card,
            front: Swift_Front,
            back: Swift_Back,
            left: Swift_Left,
            right: Swift_Right,
        },
        name: 'Swift',
        description: 'Swift comes with Aerodynamic Fibre 4 Blade & Heavy 12" Spiral Guard and Copper Motor with 15mm CRC Laminations and Fibre Base with Rubber Fitting.',
        sweepSize: '300',
        rpm: '2600',
        power: '70',
        powerSource: 'Electricity',
        color: ['Black',],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic Fibre 4 Blade',
            'Full Copper Motor with 15mm CRC Laminations',
            'Fibre Base with Rubber Fitting',
            'Heavy 12" Spiral Guard',
        ],
    },
];