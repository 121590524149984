import React, { useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { categoryMap } from '../../_helpers/data/category';
import { SocialMediaShare } from '../socialMedia';
import { GetDetailsModal } from './GetDetailsModal';
import { LandscapeBg } from '../../images/background';

function ProductDetails() {
    const location = useLocation();
    console.log('location -- key_id', location.state.key_id);
    console.log('location -- type', location.state.type);
    console.log('categoryMap1', categoryMap);
    console.log('categoryMap2', categoryMap[location.state.type]);
    console.log('categoryMap3', categoryMap[location.state.type][location.state.key_id]);
    console.log('keyValue', (location.state.key_id - 1));
    const keyValue = location.state.key_id - 1;
    const category = location.state.type;

    let { fanId } = useParams();

    const [openModal, setOpenModal] = useState(false);
    const [selectImage, setSelectImage] = useState();

    const handleTab = (index) => {
        setSelectImage(index);
    };
    return (
        <div style={{background:`url(${LandscapeBg})`,backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
            <div className='product-details'>
                <div className='card-wrapper'>
                    <div className='card'>
                        {/*-----Cardd Left --*/}
                        {location.state.type === 'CinniSmallFan'||location.state.type==="CinniCelingingFan" || location.state.type==="CinniTableFan" ||location.state.type==="CinniPedestalFan" ||location.state.type==="CinniMiniPedestalFan"  ? (
                            <>
                        <div className='product-imgs' style={{marginTop:"0em"}}>
                        <h2 className='product-title' style={{marginTop:"2em"}}>
                                {category}
                            </h2>
                            <div className='img-display Image-display'>
                                <div className='img-showcase'>
                                
                                    {selectImage ? (<img src={selectImage} alt='no-img' />)
                                        :
                                        (<img src={categoryMap[location.state.type][keyValue].image.front} alt='no-img' />)
                                    }
                                </div>
                                <div className='product-card-title'>
                                    <p>{categoryMap[location.state.type][keyValue].name}</p>
                                    <p>{categoryMap[location.state.type][keyValue].description}</p>
                                </div>
                            </div>
                            <div className='img-select'>
                                <div className='img-item'>
                                    <a data-id="1">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.front}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.front)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="2">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.back}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.back)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="3">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.left}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.left)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="4">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.right}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.right)}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                            </>
                        ):(
                            <>
                            <div className='product-imgs'>
                        <h2 className='product-title'>
                                {category}
                            </h2>
                            <div className='img-display'>
                                <div className='img-showcase'>
                                
                                    {selectImage ? (<img src={selectImage} alt='no-img' />)
                                        :
                                        (<img src={categoryMap[location.state.type][keyValue].image.front} alt='no-img' />)
                                    }
                                </div>
                                <div className='product-card-title'>
                                    <p>{categoryMap[location.state.type][keyValue].name}</p>
                                    <p>{categoryMap[location.state.type][keyValue].description}</p>
                                </div>
                            </div>
                            <div className='img-select'>
                                <div className='img-item'>
                                    <a data-id="1">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.front}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.front)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="2">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.back}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.back)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="3">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.left}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.left)}
                                        />
                                    </a>
                                </div>
                                <div className='img-item'>
                                    <a data-id="4">
                                        <img
                                            src={categoryMap[location.state.type][keyValue].image.right}
                                            alt='fan-img'
                                            onClick={() => handleTab(categoryMap[location.state.type][keyValue].image.right)}
                                        />
                                    </a>
                                </div>
                            </div>

                        </div>
                        </>
                        )}
                        
                        {/*-----Cardd Right --*/}
                        <div className='product-content'>
                            <div className='product-details'>
                                <h2>about this item:</h2>
                                <p><ul>
                                    {categoryMap[location.state.type][keyValue].features.map((it, index) => {
                                        return (
                                            <>
                                                <li key={index} className='about-items-container'><span>{it}</span></li>
                                            </>
                                        );
                                    })}
                                </ul></p>
                            </div>
                            <div className='product-details'>
                                <h2>Specifications:</h2>
                                <ul>
                                    <li>Name: <span>{categoryMap[location.state.type][keyValue].name}</span></li>
                                    <li>Sweep Size: <span>{categoryMap[location.state.type][keyValue].sweepSize}mm</span></li>
                                    <li>RPM: <span>{categoryMap[location.state.type][keyValue].rpm}</span></li>
                                    <li>Power: <span>{categoryMap[location.state.type][keyValue].power} watts</span></li>
                                    <li>Power Source: <span>{categoryMap[location.state.type][keyValue].powerSource}</span></li>
                                    <li>Number Of Blades: <span>{categoryMap[location.state.type][keyValue].numberOfBlades}</span></li>
                                    <li>Warrenty: <span>{categoryMap[location.state.type][keyValue].warrenty} Years</span></li>
                                    <li>Color: {categoryMap[location.state.type][keyValue].color.map((it, index) => {
                                        return (
                                            <>
                                                <span key={index}>{it}, </span>
                                            </>
                                        );
                                    })}
                                    </li>

                                </ul>
                                <div className='modal-container'>
                                <button type='button' className='query-btn'>
                                <a href='tel:9415672328'>Make a Query</a>
                               </button>
                               <button type='button' className='details-btn' onClick={() => { setOpenModal(true) }}>
                                Get Details
                              </button>
                            {openModal &&
                                <GetDetailsModal
                                    closeModel={setOpenModal}
                                    prodImg={categoryMap[location.state.type][keyValue].image.front}
                                    prodName={categoryMap[location.state.type][keyValue].name}
                                    prodDesc={categoryMap[location.state.type][keyValue].description}
                                    prodCategory={location.state.type}
                                />
                            }
                                </div>
                            </div>
                            <div className='purchase-info'>
                            <div>
                            <label>Qty:</label>
                            <input type='number' min='0' value='1' />
                            </div>
                            <div className='share-links'>
                            <p>Share to:</p>
                            <SocialMediaShare
                                fanName={categoryMap[location.state.type][keyValue].name}
                                fanCategory={location.state.type}
                            />
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ProductDetails };