import {
    Delux_Front_Card,
    Delux_Front,

    Gold_Front_Card,
    Gold_Front,

    Kisan_Delux_Front_Card,
    Kisan_Delux_Front,

    Kisan_Front_Card,
    Kisan_Front,

    Power_Front_Card,
    Power_Front,

    Sri_Front_Card,
    Sri_Front,

    Super_Front_Card,
    Super_Front,

    Twister_Front_Card,
    Twister_Front,
} from '../../images/cinniPedestalFan';

export const cinniPedestalFanData = [
    {
        id: 1,
        image: {
            card: Super_Front_Card,
            front: Super_Front,
            back: Super_Front,
            left: Super_Front,
            right: Super_Front,
        },
        name: 'Super',
        sweepSize: '450',
        rpm: '2580',
        power: '105',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Alluminium 2 Blade',
            '15" Heavy Base with rubber fitting',
            'Full Copper Motor with 19mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 2,
        image: {
            card: Kisan_Front_Card,
            front: Kisan_Front,
            back: Kisan_Front,
            left: Kisan_Front,
            right: Kisan_Front,
        },
        name: 'Kisan',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Almunium 2 Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 3,
        image: {
            card: Power_Front_Card,
            front: Power_Front,
            back: Power_Front,
            left: Power_Front,
            right: Power_Front,
        },
        name: 'Power',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Almunium 2 Blade',
            '15" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Powder Coated Tiger Jali',
        ],
    },
    {
        id: 4,
        image: {
            card: Sri_Front_Card,
            front: Sri_Front,
            back: Sri_Front,
            left: Sri_Front,
            right: Sri_Front,
        },
        name: 'Sri',
        sweepSize: '450',
        rpm: '2600',
        power: '110',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Almunium 2 Blade',
            '18" Heavy Ring Base',
            'Full Copper Motor with 3/4" CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },

    {
        id: 5,
        image: {
            card: Delux_Front_Card,
            front: Delux_Front,
            back: Delux_Front,
            left: Delux_Front,
            right: Delux_Front,
        },
        name: 'Deluxe',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Silver'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aluminium 2 Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },
    {
        id: 6,
        image: {
            card: Kisan_Delux_Front_Card,
            front: Kisan_Delux_Front,
            back: Kisan_Delux_Front,
            left: Kisan_Delux_Front,
            right: Kisan_Delux_Front,
        },
        name: 'Kisan DLX',
        sweepSize: '450',
        rpm: '2700',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '2',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aluminium 2 Blade',
            '18" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },
    {
        id: 7,
        image: {
            card: Gold_Front_Card,
            front: Gold_Front,
            back: Gold_Front,
            left: Gold_Front,
            right: Gold_Front,
        },
        name: 'Gold',
        sweepSize: '450',
        rpm: '2300',
        power: '120',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic Fibre 3 Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },
    {
        id: 8,
        image: {
            card: Twister_Front_Card,
            front: Twister_Front,
            back: Twister_Front,
            left: Twister_Front,
            right: Twister_Front,
        },
        name: 'Twister',
        sweepSize: '450',
        rpm: '1400',
        power: '100',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Aerodynamic AL 3 Blade (Nickel Chrome)',
            '16" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 1" CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },
    {
        id: 9,
        image: {
            card: 'Swastik',
            front: 'Swastik',
            back: 'Swastik',
            left: 'Swastik',
            right: 'Swastik',
        },
        name: 'Swastik',
        sweepSize: '450',
        rpm: '2600',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Almunium 4 Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            'Nickel Chrome Coated Tiger Guard',
        ],
    },
    {
        id: 10,
        image: {
            card: 'Swastik_Black',
            front: 'Swastik_Black',
            back: 'Swastik_Black',
            left: 'Swastik_Black',
            right: 'Swastik_Black',
        },
        name: 'Swastik',
        sweepSize: '450',
        rpm: '2600',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '4',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Almunium 4 Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 25mm CRC Laminations',
            'Heavy 12 Patti Spiral Guard',
        ],
    },
    {
        id: 11,
        image: {
            card: 'Twister_Fibre_Front',
            front: 'Twister_Fibre_Front',
            back: 'Twister_Fibre_Front',
            left: 'Twister_Fibre_Front',
            right: 'Twister_Fibre_Front',
        },
        name: 'Twister',
        sweepSize: '450',
        rpm: '1400',
        power: '120',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '3',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            'Fibre 3 Blade',
            '16" Heavy Base with Rubber Fitting',
            'Full Copper Motor with 1" CRC Laminations',
            '135 Wire Nickel Chrome Coated Tiger Jali',
        ],
    },
    {
        id: 12,
        image: {
            card: 'Star Cooler',
            front: 'Star Cooler',
            back: 'Star Cooler',
            left: 'Star Cooler',
            right: 'Star Cooler',
        },
        name: 'Star Cooler',
        sweepSize: '450',
        rpm: '1350',
        power: '115',
        powerSource: 'Electricity',
        color: ['Black'],
        numberOfBlades: '5',
        warrenty: '1',
        features: [
            'High Speed Motor',
            'Higher Air Delivery & Air Thrust',
            '5 Fibre Blade',
            '16" Heavy Base with Rubber Fittings',
            'Full Copper Motor with 19mm (108mm core) CRC Laminations',
            'Black Powder Coated Tiger Jali',
        ],
    },
];