import React from 'react';
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardActions, CardContent, CardMedia, Button, Typography, Divider, Popover } from '@mui/material';
import { cinniTableFanData } from '../../../_helpers/data';
import { SocialMedia } from '../../share/SocialMedia';

function CinniTableFan() {
    const navigate = useNavigate();

    const handleClick = (status, method, fanName) => {
        console.log('handleClick', status, method);
        navigate(`/productDetails/${method}/${fanName}`, { state: { key_id: status, type: method } });
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverClick = (event) => {
      console.log("event", event.currentTarget);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <div className='bg-color'>
                <div className='fan-heading'>
                    <p>Table <span>Fan</span></p>
                </div>
                <div className='fan-card-container fx-wrp'>
                    {cinniTableFanData.map((it, index) => {
                        return (
                            <>
                                <div className='m-5'>
                                    <Card sx={{ minWidth: 370,borderRadius:"30px" }} key={index} className='fn-crd'>
                                        <CardHeader sx={{color:"#00558C"}}
                                            title={`Cinni ${it.name}`}
                                        />
                                      
                                        <CardMedia
                                            component="img"
                                            height="240"
                                            image={it.image.card}
                                            alt={it.name}
                                            className='img-mx-length'
                                        />
                                      
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                <ul className='cinni-desc-list'>
                                                    <li>SWEEP: <span>{it.sweepSize} MM</span></li>
                                                    <li>SPEED: <span>{it.rpm} RPM</span></li>
                                                    <li>VARIENT: {it.color.map((colName, index) => {
                                                        return (
                                                            <>
                                                                <span key={index}>{colName}</span>
                                                            </>
                                                        );
                                                    })}
                                                    </li>
                                                </ul>
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={{ padding: "1em" }}>
                                            <Button size="small" 
                                            sx={{
                                                "&:hover": {
                                                    background: "transparent",
                                                    color: "#FCE302",
                                                },
                                                color: "#333",
                                                background: "#FCE302",
                                                borderRadius: "20px",
                                                padding: "0.5em",
                                                width: "100px",
                                                textTransform: "capitalize",
                                                fontFamily:"Outfit"
                                                }}
                                                onClick={handlePopoverClick}>Share</Button>
                                              <Button size="small"  sx={{
                                                "&:hover": {
                                                  background: "transparent",
                                                  color: "#FCE302",
                                                },
                                                color: "#333",
                                                background: "#FCE302",
                                                borderRadius: "20px",
                                                padding: "0.5em",
                                                width: "120px",
                                                textTransform: "capitalize",
                                                fontFamily:"Outfit"
                                              }} onClick={() => handleClick(it.id, 'CinniTableFan', it.name)}>Learn More</Button>
                                        </CardActions>
                                    </Card>
                                    <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                            vertical: -80,
                                            horizontal: "left",
                                            }}
                                        >
                                            <Typography sx={{ p: 1 }}>
                                            <SocialMedia />
                                            </Typography>
                                </Popover>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export { CinniTableFan };