import { Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Anil_kumar,Amit,Deepak } from "../../../images/owners";
import { curve_svg, Arrow_green } from "../../../images/background";

function OurTeam() {
    const theme = useTheme();
    const stylesheet= useMediaQuery(theme.breakpoints.between("xs","2500"));
    const ImageHidden = useMediaQuery(theme.breakpoints.between('768','900'))
    const titleResponsive = useMediaQuery(theme.breakpoints.between('xs','1198'))

    const title = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"24px",
            fontWeight:"500",
            color:"#00558C"
        })
    }
    const name = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"40px",
            fontWeight:"700",
            color:"#00558C"
        })
    }
    const position = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"32px",
            fontWeight:"500",
            color:"#333"
        })
    }
    const title1 = {
        ...(stylesheet && {
            fontFamily:"Outfit",
            fontSize:"24px",
            fontWeight:"500",
            color:"#333"
        })
    }
    const Image_hidden = {
        ...(ImageHidden&&{
             display:"none"
        })
    }

    const resTitle = {
        ...(titleResponsive&&{
            padding:"1em 1em",
        })
    }
  return (
      <div id="team" style={{backgroundImage:`url(${curve_svg})`,backgroundSize:"100%",backgroundRepeat:"no-repeat"}} className="svg-container">
         <Container maxWidth='lg' sx={{mt:5}}>
         <Typography sx={{...title,...resTitle}}>Our Team</Typography>
        <Stack mt={3} direction={{xs:"column",sm:"column",md:"row",lg:"row",xl:"row"}}>
           <Box sx={{zIndex:"100"}} >
            <Box className="team-card">
                <img src={Anil_kumar}/>
                <Typography sx={{...name}}>Anil Kumar Gupta</Typography>
                <Typography sx={{...position}}>CEO</Typography>
                <Typography sx={{...title1}}>Founder of Shree Maya Fans</Typography>
            </Box>
            <Stack mt={5} direction={{xs:"row",sm:"row",medium:"row",lg:"row",xl:"row"}} spacing={{ xs: 1, sm: 2, md: 4,lg:20 }}>
                <Box className="team-card">
                <img src={Amit}/>
                <Typography sx={{...name}}>Amit Gupta</Typography>
                <Typography sx={{...position}}>Production Head</Typography>
                <Typography sx={{...title1}}>Production Head of Shree Maya Fans</Typography>
                </Box>
                <Box className="team-card">
                <img src={Deepak}/>
                <Typography sx={{...name}}>Deepak Gupta</Typography>
                <Typography sx={{...position}}>Marketing Head</Typography>
                <Typography sx={{...title1}}>Marketing Head of Shree Maya Fans</Typography>
                </Box>
            </Stack>
           </Box>
           <Box className="green-arrow" sx={{...Image_hidden}}>
            <img src={Arrow_green} alt=""/>
           </Box>
        </Stack>
         </Container>
      </div>
  );
}
export { OurTeam };
