import React from 'react';
import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Green_border } from '../../images/icons';

function AboutUs() {
    const theme = useTheme(); 

    return (
        <>
            <Container maxWidth="xl" id="about">
               <Box className="flexBox">
                 <Box>
                   <img src={Green_border} alt="green-border-icon"/>
                 </Box>
                 <Box className='contentBox'>
                 
                  <h1 className='aboutus-title'>About us</h1>
                  <p className='aboutus-heading'>
                 <span style={{color:"#CDCDCD"}}>Shree Maya Fans present</span> <span style={{color:"#00558C"}}>a lifetime of blissful comfort,</span>
                  </p>
                  <p className='aboutus-content'>
                    In a style of your personal choice. At Shree Maya Fans, we work tirelessly to ensure a comfortable lifestyle for you and have been doing so since the last 28 years. Our break through technology optimises air delivery result in significant higher air spread and much larger air voulme compared to conventional fans. We have but one aim to make and market fans to suit every budget and every prupose. We, believe that quality is a key factor in building customer trust.
                  </p>
                
                 </Box>
               </Box>
            </Container>
        </>
    );
}

export { AboutUs };