import React from 'react';
import {
    FacebookShareButton,
    WhatsappShareButton,
    InstapaperShareButton
} from "react-share";
import { Facebook_Icon,Instagram_icon,Whatsapp_icon } from '../../images/icons';

function SocialMediaShare(props) {
    const shareUrl = `${window.location.href}`;
    const title = `${props.fanCategory}`;

    return (
        <>
            <a>
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="Demo__some-network__share-button"
                >
                  <img src={Facebook_Icon} style={{width:"20px"}}/>
                </FacebookShareButton>
            </a>
            <a>
                <WhatsappShareButton
                    url={shareUrl}
                    quote={title}
                    className="Demo__some-network__share-button"
                >
                    <img src={Whatsapp_icon} style={{width:"20px"}}/>
                </WhatsappShareButton>
            </a>
            <a href='https://instagram.com/shri_maya1993?igshid=MzRlODBiNWFlZA=='>
                <img src={Instagram_icon} style={{width:"20px"}}/>
            </a>
           
        </>
    );
}

export { SocialMediaShare };